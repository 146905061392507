// src/components/FAQItem.tsx

import { useState } from "react";
import { FAQ, ContentBlock } from "./types";

interface FAQItemProps {
  faq: FAQ;
}

const FAQItem: React.FC<FAQItemProps> = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  // Function to render different content block types
  const renderContentBlock = (block: ContentBlock, index: number) => {
    switch (block.type) {
      case "paragraph":
        return (
          <p key={index} className="text-gray-600 mb-2">
            {block.content}
          </p>
        );
      case "list":
        return (
          <ul key={index} className="list-disc list-inside text-gray-600 mb-2">
            {block.items.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        );
      case "bold":
        return (
          <p key={index} className="text-gray-800 font-bold mb-2">
            {block.content}
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full flex justify-between items-center py-4 text-left focus:outline-none"
        onClick={toggleFAQ}
        aria-expanded={isOpen}
        aria-controls={`faq-${faq.question}`}
      >
        <span className="text-gray-800 font-medium">{faq.question}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-300 ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        id={`faq-${faq.question}`}
        className={`overflow-hidden transition-all duration-300 ${
          isOpen ? "max-h-96 py-4" : "max-h-0"
        }`}
      >
        {faq.answer.map((block, index) => renderContentBlock(block, index))}
      </div>
    </div>
  );
};

export default FAQItem;
