// src/context/NuvoContext.tsx

import React, {
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
} from "react";
import { LoginResponse } from "../types/user.types";
import { getLocalStorage } from "../helpers/authHelper";
type NuvoContextType = {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  user: LoginResponse | null;
  setUser: Dispatch<SetStateAction<LoginResponse | null>>;
  isAuthenticated: boolean;
  handleError: (error: unknown) => void;
};

export const NuvoContext = createContext<NuvoContextType | undefined>(
  undefined
);

export const useNuvoContext = (): NuvoContextType => {
  const context = useContext(NuvoContext);
  if (context === undefined) {
    throw new Error("useNuvoContext must be used within a NuvoProvider");
  }
  return context;
};

export const NuvoProvider = ({ children }: { children: ReactNode }) => {
  const [render, setRender] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<LoginResponse | null>(null);
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);

  const handleError = (error: unknown) => {
    console.error("An error occurred:", error);
  };

  useEffect(() => {
    const storedUser: LoginResponse | null = getLocalStorage("nuvo_user_cred");
    if (storedUser) {
      setUser(storedUser);
      setIsAuthenticated(true);
    } else {
      setUser(null);
      setIsAuthenticated(false);
    }
  }, [render]);

  const contextValue: NuvoContextType = {
    render,
    setRender,
    user,
    setUser,
    isAuthenticated,
    handleError,
  };

  return (
    <NuvoContext.Provider value={contextValue}>{children}</NuvoContext.Provider>
  );
};
