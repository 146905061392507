import { motion, Variants } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

const containerVariants: Variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const fadeInUpVariants: Variants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.6 },
  },
};

export const FirstSection = () => {
  return (
    <motion.section
      className="w-11/12 mx-auto py-10"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h1
        className="text-xl md:text-2xl font-semibold text-center text-gray-800"
        variants={fadeInUpVariants}
      >
        Premium Quality Surgical Sutures Manufacturer in India
      </motion.h1>

      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center"
        variants={fadeInUpVariants}
      >
        <motion.div variants={fadeInUpVariants}>
          <LazyLoadImage
            src="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728050952/humq16n2htvkl9p5pwnh.webp"
            alt="Surgical Sutures"
            effect="blur"
            loading="lazy"
            className="w-full h-auto object-cover"
          />
        </motion.div>

        <motion.div className="flex flex-col gap-6" variants={fadeInUpVariants}>
          <p className="text-sm md:text-base  text-gray-600 text-justify">
            Nuvo Medsurg is one of the most reliable companies in the medical
            sector. Located in Bahadurgarh, Haryana, on the outskirts of Delhi,
            our quality manufacturing unit stands as a testament to our
            commitment. As a leading surgical suture manufacturer, Nuvo Medsurg
            ensures top-quality products for all your surgical needs. We believe
            in serving mankind with our best endeavors, prioritizing both
            quality and affordability.
          </p>
          <p className="text-sm md:text-base  text-gray-600 text-justify">
            We offer a wide range of products to satisfy the needs of the
            healthcare sector in its quest for quality and innovative solutions.
            In addition to our suture products, Nuvo Medsurg supplies various
            medical laboratory instruments to enhance diagnostic capabilities.
            With our PAN India presence, we are becoming a reliable partner for
            the medical fraternity, empowering them to extend their best
            services to human lives.
          </p>
        </motion.div>
      </motion.div>
    </motion.section>
  );
};
