import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { FaPlus, FaTrash } from "react-icons/fa";
import * as Yup from "yup";
import { LoginResponse, UserAddress } from "../../../../types/user.types";
import {
  createAddressHandler,
  deleteAddressHandler,
  getAddressHandler,
} from "../../../../handlers/addressHandler";
import toast, { LoaderIcon } from "react-hot-toast";
import { getLocalStorage } from "../../../../helpers/authHelper";
import TextInput from "../../../shared/core/textInput";
import FieldGroupRender from "../../../shared/core/fieldGroupRender";

const validationSchema = Yup.object().shape({
  doorNo: Yup.string().required("Door number is required"),
  streetName: Yup.string().required("Street name is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .matches(/^\d{6}$/, "Invalid pincode format")
    .required("Pincode is required"),
  landMark: Yup.string().nullable(),
  type: Yup.string()
    .oneOf(["Office", "Personal"], "Invalid address type")
    .required("Address type is required"),
});

const Addresses: React.FC = () => {
  const [addressData, setAddressData] = useState<UserAddress[]>([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAddresses = async () => {
    setIsLoading(true);
    try {
      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
      if (user && user.token) {
        const data = await getAddressHandler(user.token);
        setAddressData(data);
      }
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (addressId: string) => {
    try {
      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
      if (user && user.token) {
        await deleteAddressHandler(addressId, user.token);
        setAddressData((prev) => prev.filter((a) => a._id !== addressId));
        toast.success("Address deleted successfully");
      }
    } catch (error) {
      console.error("Failed to delete address:", error);
      toast.error("Failed to delete address");
    }
  };

  const handleAddAddress = async (newAddress: UserAddress) => {
    try {
      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
      if (user && user.token) {
        // Assuming createAddressHandler returns the new address object
        const createdAddress = await createAddressHandler(
          newAddress.streetName,
          newAddress.doorNo,
          newAddress.city,
          newAddress.state,
          newAddress.pincode,
          user.token,
          newAddress.landMark,
          newAddress.type
        );

        const formattedAddress: UserAddress = {
          _id: createdAddress._id,
          streetName: createdAddress.streetName,
          doorNo: createdAddress.doorNo,
          city: createdAddress.city,
          state: createdAddress.state,
          pincode: createdAddress.pincode,
          landMark: createdAddress.landMark,
          type: createdAddress.type,
        };

        setAddressData((prev) => [...prev, formattedAddress]);
        toast.success("Address added successfully");
      }
    } catch (error) {
      console.error("Failed to add address:", error);
      toast.error("Failed to add address");
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-lg font-medium">Manage Addresses</h1>
        {!isAdding && (
          <button
            className="flex items-center gap-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            onClick={() => setIsAdding(true)}
          >
            <FaPlus />
            Add Address
          </button>
        )}
        {isLoading && <LoaderIcon />}
      </div>
      <div className="space-y-4 mt-6">
        {addressData.map((address) => (
          <FieldGroupRender
            key={address._id}
            // className="border p-4 rounded-md shadow-sm flex justify-between items-center"
            title={`Address Details - ${address.type}`}
            buttonElements={[
              <button
                className="text-red-500 hover:text-red-700"
                onClick={() => handleDelete(address._id)}
              >
                <FaTrash />
              </button>,
            ]}
          >
            <div>
              <p>Door No: {address.doorNo}</p>
              <p>Street Name: {address.streetName}</p>
              <p>City: {address.city}</p>
              <p>State: {address.state}</p>
              <p>Pincode: {address.pincode}</p>
              <p>Landmark: {address.landMark || "N/A"}</p>
              <p>Type: {address.type}</p>
            </div>
          </FieldGroupRender>
        ))}
      </div>
      {isAdding && (
        <div className="bg-white mt-6 border p-4 rounded-md shadow-md">
          <h2 className="text-lg font-medium mb-4">Add New Address</h2>
          <Formik
            initialValues={{
              doorNo: "",
              streetName: "",
              city: "",
              state: "",
              pincode: "",
              landMark: "",
              type: "Personal",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleAddAddress(values as UserAddress);
              resetForm();
              setIsAdding(false);
            }}
          >
            {({ values, handleChange, errors, touched }) => (
              <Form className="space-y-4">
                <TextInput
                  id="doorNo"
                  name="doorNo"
                  label="Door No"
                  value={values.doorNo}
                  onChange={handleChange}
                  placeholder="Enter Door No"
                />
                {touched.doorNo && errors.doorNo && (
                  <p className="text-red-500 text-sm">{errors.doorNo}</p>
                )}
                <TextInput
                  id="streetName"
                  name="streetName"
                  label="Street Name"
                  value={values.streetName}
                  onChange={handleChange}
                  placeholder="Enter Street Name"
                />
                {touched.streetName && errors.streetName && (
                  <p className="text-red-500 text-sm">{errors.streetName}</p>
                )}
                <TextInput
                  id="city"
                  name="city"
                  label="City"
                  value={values.city}
                  onChange={handleChange}
                  placeholder="Enter City"
                />
                {touched.city && errors.city && (
                  <p className="text-red-500 text-sm">{errors.city}</p>
                )}
                <TextInput
                  id="state"
                  name="state"
                  label="State"
                  value={values.state}
                  onChange={handleChange}
                  placeholder="Enter State"
                />
                {touched.state && errors.state && (
                  <p className="text-red-500 text-sm">{errors.state}</p>
                )}
                <TextInput
                  id="pincode"
                  name="pincode"
                  label="Pincode"
                  value={values.pincode}
                  onChange={handleChange}
                  placeholder="Enter Pincode"
                />
                {touched.pincode && errors.pincode && (
                  <p className="text-red-500 text-sm">{errors.pincode}</p>
                )}
                <TextInput
                  id="landMark"
                  name="landMark"
                  label="Landmark"
                  value={values.landMark}
                  onChange={handleChange}
                  placeholder="Enter Landmark"
                />
                <div>
                  <label htmlFor="type">Type</label>
                  <select
                    id="type"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    className="block w-full mt-1 border border-gray-300 rounded"
                  >
                    <option value="Personal">Personal</option>
                    <option value="Office">Office</option>
                  </select>
                </div>
                <div className="flex gap-4">
                  <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="w-full bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400"
                    onClick={() => setIsAdding(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default Addresses;
