import { useEffect, useState } from "react";
import { getLocalStorage } from "../../../helpers/authHelper";
import { LoginResponse, UserAddress } from "../../../types/user.types";
import { getAddressHandler } from "../../../handlers/addressHandler";
import { useFormikContext } from "formik";
import FieldGroupRender from "../../shared/core/fieldGroupRender";
import { LoaderIcon } from "react-hot-toast";
import Label from "../../shared/core/label";

export type SelectAddressViewProps = {
  selectedAddressId: string | null;
};

export const SelectAddressView = () => {
  const [addressData, setAddressData] = useState<UserAddress[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { values, setFieldValue } = useFormikContext<{
    deliveryAddress: string | null;
  }>();

  const fetchAddresses = async () => {
    setIsLoading(true);
    try {
      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
      if (user?.token) {
        const data = await getAddressHandler(user.token);
        setAddressData(data);
      }
    } catch (error) {
      console.error("Failed to fetch addresses:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  return (
    <div className="flex flex-col gap-4 py-2">
      {isLoading ? (
        <LoaderIcon />
      ) : (
        <>
          {addressData.length > 0 ? (
            <div className="bg-white p-4 rounded-md shadow-md flex flex-col gap-2 text-sm">
              {addressData.map((address) => (
                <Label
                  key={address._id}
                  htmlFor="deliveryAddress"
                  className="flex flex-row items-start gap-2 border rounded p-3"
                >
                  <input
                    type="radio"
                    name="deliveryAddress"
                    value={address._id}
                    checked={values?.deliveryAddress === address._id}
                    onChange={() => {
                      setFieldValue("deliveryAddress", address._id);
                    }}
                  />
                  <div className="flex flex-col">
                    <span className="font-semibold">
                      {address.type} Address
                    </span>
                    <span>
                      {address.doorNo}, {address.streetName}
                    </span>
                    <span>{address.landMark}</span>
                    <span>
                      {address.city}, {address.state} - {address.pincode}
                    </span>
                  </div>
                </Label>
              ))}
            </div>
          ) : (
            <p>No addresses found. Please add one.</p>
          )}
        </>
      )}
    </div>
  );
};
