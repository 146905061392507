// src/components/FAQs.tsx

import { FAQ } from "./types";
import FAQItem from "./FaqItem";

interface FAQsProps {
  faqs: FAQ[];
}

const FAQs: React.FC<FAQsProps> = ({ faqs }) => {
  return (
    <div className="w-full max-w-3xl mx-auto px-4">
      <h2 className="text-xl font-semibold mb-6 text-center">
        Frequently Asked Questions
      </h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <FAQItem key={index} faq={faq} />
        ))}
      </div>
    </div>
  );
};

export default FAQs;
