export interface Product {
  id: string;
  name: string;
  image: string;
  slug: string;
}

// Define ProductCategories as a readonly tuple of string literals
export const ProductCategories = [
  "ADHESIVE TAPES",
  "BANDAGES",
  "DISINFECTANT WIPES",
  "GLOVES",
  "NON-STERILE DRESSINGS",
  "STERILE DRESSINGS",
  "SURGICAL DISPOSABLE",
  "SURGICAL SUTURES",
  "SKIN STAPLER",
] as const;

export type ProductCategory = (typeof ProductCategories)[number];

export const FeaturedCategories: ProductCategory[] = [
  "SURGICAL SUTURES",
  "SKIN STAPLER",
  //   "DISINFECTANT WIPES",
];

export const DressingsCategories: ProductCategory[] = [
  //   "ADHESIVE TAPES",
  //   "BANDAGES",
  //   "GLOVES",
  "NON-STERILE DRESSINGS",
  "STERILE DRESSINGS",
];

export const DisposableCategories: ProductCategory[] = [
  //   "ADHESIVE TAPES",
  //   "BANDAGES",
  "GLOVES",
  "SURGICAL DISPOSABLE",
];

export enum ActiveTabTypeEnum {
  Featured = "Featured",
  Dressings = "Dressings",
  Disposables = "Disposables",
}

export type ActiveTabType = keyof typeof ActiveTabTypeEnum;

export const tabs: ActiveTabType[] = [
  ActiveTabTypeEnum.Featured,
  ActiveTabTypeEnum.Dressings,
  ActiveTabTypeEnum.Disposables,
];

export const tabToCategoriesMap: Record<ActiveTabType, ProductCategory[]> = {
  [ActiveTabTypeEnum.Featured]: FeaturedCategories,
  [ActiveTabTypeEnum.Dressings]: DressingsCategories,
  [ActiveTabTypeEnum.Disposables]: DisposableCategories,
};
