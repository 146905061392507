export const metaData: CategorywiseMeta = {
  "adhesive-tapes": {
    title: "Adhesive Tapes Title",
    description: "Description for Adhesive Tapes",
  },
  bandages: {
    title:
      "Buy Bandages Products Online in India | Buy Bandages Online -Healthcare Supplies",
    description:
      "Order Bandages online & get upto 60% OFF on Brand Name. Check out Bandages benefits and specifications. Also, get doorstep delivery PAN India. ",
  },
  "disinfectant-wipes": {
    title: "Buy Top Quality Disinfectant Wipes Online from Nuvo Medsurg",
    description:
      "Nuvo MedSurg offers baby wipes and bed bath towels for delicate baby care and practical adult hygiene. Gentle, reliable, and convenient solutions.",
  },
  gloves: {
    title: "Gloves Title",
    description: "Description for Gloves",
  },
  "non-sterile-dressings": {
    title: "Non-Sterile Dressings Title",
    description: "Description for Non-Sterile Dressings",
  },
  "sterile-dressings": {
    title:
      " Buy Top Quality Sterile Dressings for fast wound recovery | Nuvo Medsurg",
    description:
      " Nuvo MedSurg offers sterile dressings like gamjee roll, abdominal gauze, dressing pad and gauze swab, ensuring safe and effective wound management.",
  },
  "surgical-disposable": {
    title: "Buy Surgical Disposable Products Online| Nuvo Medsurg ",
    description:
      " Explore our wide range of surgical disposable products for maximum safety and hygiene. Shop now for top-quality surgical disposables and enhance your medical practice.",
  },
  "surgical-sutures": {
    title: " Buy Surgical Sutures Online | Trusted by Healthcare Experts",
    description:
      "Buy quality Surgical suture products for safe and efficient wound closure. trusted by medical professionals. Order today for reliable and durable sutures.",
  },
  "skin-stapler": {
    title:
      "Buy Skin Stapler Online- High-Quality Surgical Staplers at Best Price",
    description:
      " Shop high-quality skin staplers online for precise and efficient wound closure. Explore our range of affordable surgical staplers. Fast shipping and secure checkout.",
  },
};

type HtmlMetaType = {
  title: string;
  description: string;
};

export type CategoryType =
  | "ADHESIVE TAPES"
  | "BANDAGES"
  | "DISINFECTANT WIPES"
  | "GLOVES"
  | "NON-STERILE DRESSINGS"
  | "STERILE DRESSINGS"
  | "SURGICAL DISPOSABLE"
  | "SURGICAL SUTURES"
  | "SKIN STAPLER";

export type CategorywiseMeta = {
  [key in string]: HtmlMetaType;
};
