import React from 'react';
import { Helmet } from 'react-helmet';

interface JSONLDProps {
  data: Record<string, any>;
}

const JSONLD: React.FC<JSONLDProps> = ({ data }) => (
  <Helmet>
    <script type="application/ld+json">
      {JSON.stringify(data)}
    </script>
  </Helmet>
);

export default JSONLD;
