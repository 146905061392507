import { divClassName, h2ClassName } from "../../single-category-css";

export const SterilTwo = () => {
  return (
    <div className="flex flex-col gap-5 text-justify w-11/12 mx-auto">
      <div className={divClassName}>
        <h2 className={h2ClassName}>Types of Sterile Dressings Available</h2>
        <p className="text-sm md:text-base">
          Sterile dressings are necessary to protect wounds and promote healing.
          Choosing the proper sterile dressing is critical for good wound
          treatment, resulting in maximum healing and protection. Here are some
          of the available types:
        </p>
        <ul className="list-disc ml-10 text-sm md:text-base">
          <li>
            <strong>Gauze dressings :</strong> Gauze dressings are highly
            absorbent and breathable, perfect for cleaning and covering wounds
            while offering excellent infection prevention.
          </li>
          <li>
            <strong>Hydrocolloid Dressings:</strong> When in touch with wound
            exudate, these dressings generate a gel-like material that promotes
            a moist healing environment. They are ideal for burns and pressure
            ulcers.
          </li>
          <li>
            <strong>Foam Dressings:</strong> Designed to absorb exudate while
            keeping the wound moist, foam dressings are appropriate for mild to
            extensively oozing wounds.
          </li>
          <li>
            <strong>Transparent Film Dressings:</strong> These are thin,
            flexible, and waterproof, allowing you to monitor wound healing
            without having to remove them.
          </li>
        </ul>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>
          How to Choose the Right Sterile Dressing for Your Needs
        </h2>
        <p className="text-sm md:text-base">
          For effective wound care, choosing the appropriate sterile dressing is
          essential. Before choosing the suitable dressing material, evaluate
          the size and kind of the wound. Adhesive bandages could be sufficient
          for minor wounds or abrasions. Consider using gauze or foam dressings
          for more significant wounds requiring additional absorption and
          protection. Evaluate the dressing's breathability and moisture
          retention to encourage speedier healing. To reduce pain, ease of
          application and removal should also be considered. You can ensure the
          most outstanding wound care and healing by speaking with a healthcare
          expert who can help you choose the best solution for your
          circumstances.
        </p>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>
          Where to Buy Sterile Dressings: Affordable Options Online
        </h2>
        <p className="text-sm md:text-base">
          Finding reputable and reasonably priced solutions online is essential
          when buying sterile dressings. Look at reputable medical supply
          websites with a large selection of high-quality sterile dressings.
          These sites frequently give thorough product descriptions and consumer
          reviews, allowing you to make informed judgements. Furthermore,
          internet shopping will enable you to compare prices and read reviews
          to verify that you're receiving the most excellent deal. Always pick
          trusted suppliers to guarantee that the items fit safety regulations
          and your unique requirements.
        </p>
      </div>
    </div>
  );
};
