// src/components/TabbedCategories.tsx

import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa";
import { ActiveTabType, tabs, tabToCategoriesMap } from "./types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Shimmer from "./nuvo-shimmer";
import { SingleProductType } from "../../../../types/product.types";
import { SingleCategoryType } from "../../../../types/category.types";
import useWindowWidth from "../../../../hooks/useWindowWidth";

interface TabbedCategoriesProps {
  data: SingleProductType[];
}

const TabbedCategories: React.FC<TabbedCategoriesProps> = ({ data }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<ActiveTabType>("Featured");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getProductsForTab = (tab: ActiveTabType): SingleProductType[] => {
    const categoriesForTab = tabToCategoriesMap[tab];
    if (!categoriesForTab) {
      console.warn(`No categories found for tab: ${tab}`);
      return [];
    }

    const filteredProducts = data.filter((product) =>
      categoriesForTab.includes(product.category.name)
    );

    return filteredProducts;
  };

  const productsToDisplay = useMemo(() => {
    return getProductsForTab(activeTab);
  }, [activeTab, data]);

  const handleTabClick = (tab: ActiveTabType) => {
    if (tab !== activeTab) {
      setIsLoading(true);
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (isLoading) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  const { isMd } = useWindowWidth();
  const arraySize = isMd ? 6 : 4;
  return (
    <div className="w-11/12 mx-auto flex flex-col gap-4">
      <div
        className="flex justify-end md:justify-end sm:justify-center"
        role="tablist"
        aria-label="Product Categories"
      >
        <div className="flex space-x-4">
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => handleTabClick(tab)}
              className={`px-4 py-2 rounded-lg font-medium transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                activeTab === tab
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white"
              }`}
              role="tab"
              aria-selected={activeTab === tab}
              aria-controls={`tabpanel-${tab}`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>

      <div
        id={`tabpanel-${activeTab}`}
        role="tabpanel"
        aria-labelledby={`tab-${activeTab}`}
        className={`grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 transition-opacity duration-500 ${
          isLoading ? "opacity-0" : "opacity-100"
        }`}
      >
        {isLoading
          ? Array.from({ length: 6 }).map((_, index) => (
              <div
                key={index}
                className="cursor-pointer bg-nuvo-card shadow-md flex flex-col items-center rounded-[8px] p-4"
              >
                <Shimmer w="100%" h={200} radius="4px" />
                <div className="mt-4 w-full h-6 bg-gray-300 rounded"></div>
              </div>
            ))
          : productsToDisplay.slice(0, arraySize).map((product) => (
              <div
                key={product._id}
                onClick={() => navigate(`/products/${product.slug}`)}
                className="cursor-pointer bg-nuvo-card shadow-md flex flex-col items-center rounded-[8px] items-center p-4 hover:shadow-lg transition-shadow duration-300"
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    navigate(`/products/${product.slug}`);
                  }
                }}
                aria-label={`View details for ${product.name}`}
              >
                <div className="flex-shrink-0 h-48 w-full flex justify-center items-center">
                  <LazyLoadImage
                    className="w-8/12 h-auto mx-auto object-cover rounded-md"
                    src={product.image}
                    alt={product.name}
                    effect="blur"
                    loading="lazy"
                  />
                </div>
                <p className="mt-4 font-medium text-center text-title-6">
                  {product.name}
                </p>
              </div>
            ))}
      </div>

      <div
        onClick={() => navigate(`/categories`)}
        className="cursor-pointer bg-black w-8/12 sm:w-6/12 lg:w-4/12 text-center align-middle justify-center py-3 rounded-[8px] px-6 text-white font-medium text-title-7 flex flex-row gap-2 items-center mx-auto transition-transform duration-300 hover:scale-105"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            navigate(`/categories`);
          }
        }}
        aria-label="Shop Other Products"
      >
        <p>Shop Other Products</p>
        <FaArrowUp />
      </div>
    </div>
  );
};

export default TabbedCategories;
