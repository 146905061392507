/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SetStateAction, useEffect, useRef, useState } from "react";
import {
  getAllProducts,
  getProductBySlug,
} from "../../handlers/productHandler";
import {
  ProductCode,
  ProductMetaDataType,
  ProductVariantType,
  SingleProductType,
  TensileStrengthType,
} from "../../types/product.types";
import { ProductDetailsComponent } from "./ProductDetailsComponent";
import { useNavigate, useParams } from "react-router-dom";
import { NuvoContainer } from "../conatiner/NuvoContainer";
import { BreadCrumbs } from "../shared/BreadCrumbs";
import { unslugify } from "../../utils/UnSlugify";
import { Loader } from "../shared/Loader/Loader";
import { SimilarProducts } from "./SimilarProducts";
import { getLocalStorage, isAuth } from "../../helpers/authHelper";
import toast from "react-hot-toast";
import { ProductAddToCartType, addToCart } from "../../handlers/cartHandler";
import { AxiosError } from "axios";
import { LoginResponse } from "../../types/user.types";
import {
  cloudFrontBaseUrl,
  ProductPageLeftPanel,
  s3BaseUrl,
} from "./product-sections/ProductPageLeftPanel";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import { getMinAndMaxPrice } from "../../utils/getMinAndMaxPrice";
import { useNuvoContext } from "../../context/NuvoContext";
import { Rating } from "@smastrom/react-rating";
import NuvoTable from "../shared/table/NuvoTable";
import useWindowWidth from "../../hooks/useWindowWidth";
import { numberToINR } from "../../utils/numberToInr";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { boolean } from "yup";
import { useCart } from "../../context/NuvoCartContext";
import { SingleCartItemType } from "../../types/cart.types";
import Shimmer from "../Shimmer/Shimmer";
import TextShimmer from "../Shimmer/TextShimmer";

export const ProductDetails = () => {
  const { productName } = useParams();
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [isProductsLoading, setISProductsLoading] = useState<boolean>(false);
  const [addToCartClicked, setAddToCartClicked] = useState<boolean>(false);
  const [productData, setProductData] = useState<SingleProductType | null>(
    null
  );
  const [productsData, setProductsData] = useState<SingleProductType[] | null>(
    null
  );
  const getProduct = async (productName: string) => {
    setIsloading(true);
    try {
      const data = await getProductBySlug(productName);
      setProductData(data[0]);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const { handleError } = useNuvoContext();
  const getAllProductshandler = async () => {
    setISProductsLoading(true);
    try {
      const data = await getAllProducts();
      setProductsData(data);
      setISProductsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (productName) {
      getProduct(productName);
      getAllProductshandler();
    }
  }, [productName]);

  return (
    <>
      {(isLoading || isProductsLoading) && (
        <div className="flex flex-col md:grid md:grid-cols-12 w-full gap-5 py-10">
          {/* Left Panel */}
          <div className="hidden md:block col-span-4">
            <div className="w-full flex flex-col-reverse md:flex-row gap-3 items-center">
              {/* Placeholder Cards */}
              <div className="grid grid-cols-4 md:grid-cols-1 gap-2">
                {Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className="bg-nuvo-card rounded-[4px] flex items-center justify-center w-[50px] h-[50px] cursor-pointer ml-20"
                    >
                      <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
                    </div>
                  ))}
              </div>

              {/* Main Shimmer Card */}
              <div className="cursor-pointer bg-nuvo-card w-[300px] min-h-fit h-[150px] p-4 flex flex-col rounded-[8px]">
                <Shimmer w="100%" h={300} radius="4px 4px 0 0" />
              </div>
            </div>
          </div>

          {/* Main Content Panel */}
          <div className="w-11/12 md:col-span-8">
            <div className="justify-start w-full flex flex-col md:grid md:grid-cols-12 gap-5">
              {/* Product Information */}
              <div className="col-span-8 flex flex-col gap-5">
                <div className="flex flex-col gap-2">
                  {/* Title Shimmer */}
                  <div className="flex gap-3 items-center">
                    <p className="text-title-4 font-semibold">
                      <TextShimmer w="100%" h={50} />
                    </p>
                  </div>

                  {/* Mobile Product Page Panel */}
                  <div className="md:hidden">
                    <div className="w-full flex flex-col-reverse md:flex-row gap-3 items-center">
                      {/* Placeholder Cards */}

                      {/* Main Shimmer Card */}
                      <div className="cursor-pointer bg-nuvo-card w-[300px] min-h-fit h-[150px] p-4 flex flex-col rounded-[8px] mb-5">
                        <Shimmer w="100%" h={300} radius="4px 4px 0 0" />
                      </div>
                    </div>
                  </div>

                  {/* Subtitle Shimmer */}
                  <p className="text-title-6 font-medium">
                    <TextShimmer w="100%" h={50} />
                  </p>

                  {/* Additional Information Shimmer */}
                  <div className="gap-3 items-center hidden md:flex">
                    <TextShimmer w="100%" h={50} />
                    <span className="h-5 border border-black"></span>
                    <p>
                      <TextShimmer w="100%" h={50} />
                    </p>
                  </div>
                </div>

                <hr className="w-11/12" />

                {/* Additional Content Shimmer */}
                <p>
                  <TextShimmer w="100%" h={50} />
                </p>

                {/* Buttons */}
                <div className="cursor-pointer bg-nuvo-card min-h-fit h-12 w-[120px] p-4 flex flex-col rounded-[8px]">
                  <Shimmer w="100%" h={20} radius="4px 4px 0 0" />
                </div>

                <div className="cursor-pointer bg-nuvo-card min-h-fit h-12 w-[280px] p-4 flex flex-col rounded-[8px]">
                  <Shimmer w="100%" h={20} radius="4px 4px 0 0" />
                </div>

                <div className="hidden md:block items-center">
                  <Shimmer w="100%" h={20} radius="4px 4px 0 0" />
                </div>
                {/* Mobile Button */}
                <div className="flex md:hidden items-center">
                  <div className="cursor-pointer bg-nuvo-card min-h-fit h-12 w-[280px] p-4 flex flex-col rounded-[8px]">
                    <Shimmer w="100%" h={20} radius="4px 4px 0 0" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isLoading && !isProductsLoading && productData && productsData && (
        <ProductForm
          productData={productData}
          productsData={productsData}
          addToCartClicked={addToCartClicked}
        />
      )}
    </>
  );
};

interface ProductFormInputType {
  productData: SingleProductType;
  productsData: SingleProductType[];
  addToCartClicked: boolean;
}
interface Product {
  id: string;
  size: string;
  code: string;
  quantity: number;
}
export const ProductForm = ({
  productData,
  productsData,
  addToCartClicked,
}: ProductFormInputType) => {
  const { productName, categoryName } = useParams();
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedCode, setSelectedCode] = useState("");

  const { maxPrice, minPrice } = productData
    ? getMinAndMaxPrice(productData.variants)
    : { minPrice: 0, maxPrice: 0 };

  const selectedVariant: ProductVariantType | undefined =
    productData?.variants.find((variant) => variant.size === selectedSize);

  const selectedProductCode: ProductCode | undefined =
    selectedVariant?.codes.find((code) => code.shortCode === selectedCode);
  const { updateCartItemsCount } = useCart();
  const getProductFromLocalStorage: SingleCartItemType[] =
    getLocalStorage("nuvo_cart") || [];
  return (
    <Formik
      initialValues={{
        quantity: 1,
        size:
          productData?.category?.slug !== "surgical-sutures" ? "General" : null,
        code: null,
        originalPrice: maxPrice,
        data: productData,
        discountedPrice: minPrice,
      }}
      onSubmit={(values) => {
        if (
          getProductFromLocalStorage &&
          Array.isArray(getProductFromLocalStorage)
        ) {
          if (productData?._id) {
            if (
              values.code &&
              values.quantity &&
              values.size &&
              values.data._id
            ) {
              const existingProduct = getProductFromLocalStorage.find(
                (product) =>
                  product.code === values.code &&
                  product.size === values.size &&
                  product.id === values.data._id
              );

              if (existingProduct) {
                let existingProductQuantity = Number(existingProduct.quantity);
                let newQuantity = Number(values.quantity);

                existingProduct.quantity =
                  existingProductQuantity + newQuantity;
              } else {
                const productObject = {
                  quantity: values.quantity,
                  size: values.size,
                  code: values.code,
                  id: productData?._id,
                  data: productData,
                };
                getProductFromLocalStorage.push(productObject);
              }

              localStorage.setItem(
                "nuvo_cart",
                JSON.stringify(getProductFromLocalStorage)
              );

              updateCartItemsCount();
              toast.success("Item Added To Cart Successfully");
            } else {
              toast.error("Please select the quantity , size and code");
            }
          }
        } else {
          if (productData?._id) {
            if (values.code && values.quantity && values.size) {
              const productsArray = [
                {
                  quantity: values.quantity,
                  size: values.size,
                  code: values.code,
                  id: productData?._id,
                  data: productData,
                },
              ];
              localStorage.setItem("nuvo_cart", JSON.stringify(productsArray));
              updateCartItemsCount();
              // addItemToCart(productsArray);
            } else {
              toast.error("Please select the quantity , size and code");
            }
          }
        }
      }}
    >
      <Form>
        {productData && (
          <NuvoContainer>
            <div>
              <BreadCrumbs
                breadcrumbs={[
                  {
                    label: "Home",
                    link: "/",
                  },
                  {
                    label: "Category",
                    link: "/categories",
                  },
                  {
                    label: unslugify(categoryName as string),
                    link: `/categories/${categoryName}`,
                  },
                  {
                    label: unslugify(productName as string),
                  },
                ]}
              />
              <div className="flex flex-col md:grid md:grid-cols-12 w-full gap-5 py-10">
                <div className="hidden md:block col-span-4">
                  <ProductPageLeftPanel data={productData} />
                </div>
                <div className="w-11/12 md:col-span-8">
                  {productData && (
                    <ProductRightPanelForm
                      productData={productData}
                      addToCartClicked={addToCartClicked}
                    />
                  )}
                </div>
              </div>

              <hr className="border border-black w-full mt-5 my-10" />
              {productData && (
                <ProductDetailsComponent productData={productData} />
              )}
              {/* product Desciption goes here */}
              {productData.description && (
                <ul className="text-justify ml-5 list-disc text-xl flex flex-col gap-4 text-slate-500">
                  {productData.description.map((f) => (
                    <li className="text-sm md:text-base">{f}</li>
                  ))}
                </ul>
              )}
              <hr className="border border-black w-full my-10" />
              {productsData && productName && categoryName && (
                <SimilarProducts
                  productsData={productsData}
                  selectedProduct={productName}
                />
              )}
            </div>
          </NuvoContainer>
        )}
      </Form>
    </Formik>
  );
};

export const ProductRightPanelForm = ({
  productData,
  addToCartClicked,
}: {
  productData: SingleProductType;
  addToCartClicked: boolean;
}) => {
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [selectedCode, setSelectedCode] = useState("");
  const navigate = useNavigate();
  const getProductFromLocalStorageRef = useRef<Product[]>([]);

  useEffect(() => {
    const storedProducts = getLocalStorage("nuvo_cart");
    if (Array.isArray(storedProducts)) {
      getProductFromLocalStorageRef.current = storedProducts;
    } else {
      getProductFromLocalStorageRef.current = [];
    }
  }, []);

  const { setFieldValue, values } = useFormikContext<{
    size: string;
    code: string;
    quantity: number;
  }>();
  const quantities = Array.from({ length: 10 }, (_, i) => i + 1);

  const handleSizeChange = (event: {
    target: { value: SetStateAction<string | null> };
  }) => {
    setSelectedSize(event.target.value);
    setFieldValue("size", event.target.value);
    setSelectedCode("");
  };

  const handleCodeChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSelectedCode(event.target.value);
    setFieldValue("code", event.target.value);
  };

  const selectedVariant: ProductVariantType | undefined =
    productData?.variants.find((variant) => variant.size === values?.size);

  const selectedProductCode: ProductCode | undefined =
    selectedVariant?.codes.find((code) => code.shortCode === selectedCode);

  const { maxPrice, minPrice } = productData
    ? getMinAndMaxPrice(productData.variants)
    : { minPrice: 0, maxPrice: 0 };
  const ratingValue = 4.44;

  const { isMd } = useWindowWidth();
  return (
    <div className=" justify-start w-full flex flex-col md:grid md:grid-cols-12 gap-5">
      <div className="col-span-8 flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <div className="flex gap-3 items-center">
            <p className="text-title-5 md:text-title-4 font-semibold">
              {productData?.name}
            </p>
          </div>
          <div className="md:hidden">
            <ProductPageLeftPanel data={productData} />
          </div>
          <p className="text-title-6 font-medium">
            {productData?.genericName && productData?.genericName}
          </p>
          <div className="gap-3 items-center hidden md:flex">
            <Rating
              readOnly
              value={ratingValue}
              key={ratingValue}
              onHoverChange={() => {}}
              style={{
                maxWidth: 120,
                width: "100%",
              }}
            />
            <span className="h-5 border border-black"></span>
            <p className="text-success">200+ bought this month</p>
          </div>
        </div>

        <hr className="w-11/12" />
        {values.code && values.size && selectedVariant ? (
          <div className="flex flex-col gap-2 ">
            {selectedProductCode?.discountedPrice && (
              <div className="flex flex-col md:flex-row gap-2 md:items-center">
                <p className="font-medium text-body-1 md:text-title-3 text-black">
                  Our Price :
                  {numberToINR(
                    Number(selectedProductCode?.discountedPrice.toFixed(2))
                  )}
                </p>
                <p className="text-danger">* Inclusive of all taxes.</p>
              </div>
            )}
            {selectedProductCode?.originalPrice && (
              <p className="text-black font-bold text-body-5">
                MRP :
                {numberToINR(
                  Number(
                    selectedProductCode?.originalPrice &&
                      Math.round(selectedProductCode?.originalPrice)
                  )
                )}
              </p>
            )}
            {selectedProductCode?.discountedPrice &&
              selectedProductCode.originalPrice && (
                <>
                  <p>
                    You Save :
                    {numberToINR(
                      Number(
                        (
                          selectedProductCode?.originalPrice -
                          selectedProductCode?.discountedPrice
                        ).toFixed(2)
                      )
                    )}
                  </p>
                </>
              )}
          </div>
        ) : minPrice !== maxPrice ? (
          <div className="flex flex-row gap-5 items-center">
            <p className="text-black font-normal text-body-1 md:text-title-5">
              ({numberToINR(minPrice)}
            </p>
            -
            <p className="text-black font-normal text-body-1 md:text-title-5">
              {numberToINR(maxPrice)} )
            </p>
          </div>
        ) : (
          <div></div>
        )}
        {values.size !== "General" && (
          <Field
            className="h-12 w-[120px] px-2 rounded-lg border border-black text-sm md:text-base"
            as="select"
            id="size"
            name="size"
            onChange={handleSizeChange}
          >
            <option value="">Select size</option>
            {productData.variants.map((variant) => (
              <option key={variant.size} value={variant.size}>
                {variant.size}
              </option>
            ))}
          </Field>
        )}
        <Field
          className={`h-12 ${"w-[280px]"}  px-2 rounded-lg border border-black`}
          as="select"
          id="code"
          name="code"
          onChange={handleCodeChange}
        >
          <option value="">
            {values.size === "General" ? "Select size" : "Select code"}
          </option>
          {productData?.variants
            .find((variant) => variant.size === values?.size)
            ?.codes.map((code) => (
              <option key={code.shortCode} value={code.shortCode}>
                {code.fullCode}
              </option>
            ))}
        </Field>
        <div className="flex md:hidden items-center">
          <label
            htmlFor="quantity"
            className="mr-2 text-body-1  2xl:text-title-6 font-medium"
          >
            Quantity:
          </label>
          <Field
            className="h-10 w-[50px] 2xl:w-[150px] px-2 rounded-lg border border-black"
            type="text"
            id="quantity"
            name="quantity"
          />
          <ErrorMessage
            className="text-danger text-body-1 font-medium"
            name="quantity"
            component="div"
          />
        </div>
        <div className="flex flex-col md:hidden gap-2">
          <button
            type="submit"
            className="bg-black text-center md:mt-5 px-6 py-2 rounded-[8px] text-title-6 font-bold text-white cursor-pointer"
          >
            Add To Cart
          </button>
        </div>
      </div>
      <div className="hidden md:block col-span-4 border h-fit bg-nuvo-grey-200 border-black rounded-[8px] p-4">
        <div className="flex items-center">
          <label
            htmlFor="quantity"
            className="mr-2 text-body-1 2xl:text-title-6 font-medium"
          >
            Quantity:
          </label>
          <Field
            className="h-10 w-[50px] 2xl:w-[150px] px-2 rounded-lg border border-black"
            type="text"
            id="quantity"
            name="quantity"
          />
          <ErrorMessage
            className="text-danger text-body-1 font-medium"
            name="quantity"
            component="div"
          />
        </div>
        <div className="flex flex-col gap-2">
          <button
            type="submit"
            className="bg-black text-center md:mt-5 px-6 py-2 rounded-[8px] text-title-6 font-bold text-white cursor-pointer"
          >
            Add To Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export const ProductTable = ({
  productData,
}: {
  productData: ProductMetaDataType;
}) => {
  const columns = [
    { header: "", accessor: "id" },
    { header: "", accessor: "value" },
  ];

  const data = Object.entries(productData).map(([key, value]) => ({
    id: key,
    value: value,
  }));

  return (
    <div>
      <NuvoTable columns={columns} data={data} />
    </div>
  );
};
