// src/components/Footer.tsx

import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInView, motion } from "framer-motion";
import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaTags,
  FaPaperPlane,
  FaWhatsapp,
} from "react-icons/fa";
import logo from "../../assets/images/novoLogo.svg";
import { NuvoContainer } from "../conatiner/NuvoContainer";

export const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};
export const Footer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const footerLinks = [
    { name: "Home", path: "/" },
    { name: "Shop", path: "/categories" },
    { name: "Surgical Sutures", path: "/categories/surgical-sutures" },
    { name: "About us", path: "/about-us" },
  ];

  const productCategories = [
    { name: "Surgical Sutures", path: "/categories/surgical-sutures" },
    { name: "Surgical Disposable", path: "/categories/surgical-disposable" },
    { name: "Sterile Dressings", path: "/categories/sterile-dressings" },
    { name: "Skin Stapler", path: "/categories/skin-stapler" },
  ];

  return (
    <footer className="bg-gray-100 w-full">
      {/* Top Interactive Cards */}
      <div className="container mx-auto px-4 py-8">
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4"
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          {/* Categories Card */}
          <motion.button
            variants={itemVariants}
            className="bg-blue-100 p-4 rounded-lg shadow-md text-center hover:bg-blue-200 transition duration-300 flex flex-col items-center"
            onClick={() => navigate("/categories")}
            aria-label="Explore Categories"
          >
            <FaTags className="text-blue-500 w-8 h-8 mb-2" />
            <h3 className="text-lg font-semibold mb-1">Categories</h3>
            <p className="text-gray-700 text-sm">
              Explore our product categories
            </p>
          </motion.button>

          {/* Email Card */}
          <motion.button
            variants={itemVariants}
            className="bg-blue-200 p-4 rounded-lg shadow-md text-center hover:bg-blue-300 transition duration-300 flex flex-col items-center"
            onClick={() =>
              (window.location.href = "mailto:info@nuvomedsurg.in")
            }
            aria-label="Send Email"
          >
            <FaPaperPlane className="text-blue-500 w-8 h-8 mb-2" />
            <h3 className="text-lg font-semibold mb-1">Email</h3>
            <p className="text-gray-700 text-sm">Send us an Email</p>
          </motion.button>

          {/* WhatsApp Contact Card */}
          <motion.button
            variants={itemVariants}
            className="bg-blue-300 p-4 rounded-lg shadow-md text-center hover:bg-blue-400 transition duration-300 flex flex-col items-center"
            onClick={() =>
              (window.location.href = "https://wa.me/917082210770")
            }
            aria-label="Chat on WhatsApp"
          >
            <FaWhatsapp className="text-blue-500 w-8 h-8 mb-2" />
            <h3 className="text-lg font-semibold mb-1">Contact Us</h3>
            <p className="text-gray-700 text-sm">Chat with us on WhatsApp</p>
          </motion.button>
        </motion.div>
      </div>

      {/* Middle Section: Company Info, Links, Categories, Contact */}
      <NuvoContainer>
        <motion.div
          className="grid grid-cols-1 md:grid-cols-4 gap-8 py-12"
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          {/* Company Information */}
          <motion.div variants={itemVariants} className="space-y-4">
            <div className="flex items-center gap-2">
              <img src={logo} alt="Nuvo Medsurg Logo" className="w-10 h-10" />
              <span className="text-lg font-semibold">Nuvo Medsurg</span>
            </div>
            <hr className="border-gray-400 w-20" />
            <p className="text-gray-700">
              The Nuvo Medsurg facility is an integrated manufacturing facility,
              producing finished products as well as primary finished packaging
              itself.
            </p>
          </motion.div>

          {/* Additional Links */}
          <motion.div
            variants={itemVariants}
            className="hidden md:flex flex-col space-y-2"
          >
            <h4 className="text-lg font-semibold">Additional Links</h4>
            <hr className="border-gray-400 w-20" />
            <ul className="space-y-1">
              {footerLinks.map((link) => (
                <li key={link.name}>
                  <button
                    onClick={() => navigate(link.path)}
                    className="text-gray-700 hover:underline"
                  >
                    {link.name}
                  </button>
                </li>
              ))}
            </ul>
          </motion.div>

          {/* Product Categories */}
          <motion.div
            variants={itemVariants}
            className="hidden md:flex flex-col space-y-2"
          >
            <h4 className="text-lg font-semibold">Product Categories</h4>
            <hr className="border-gray-400 w-20" />
            <ul className="space-y-1">
              {productCategories.map((category) => (
                <li key={category.name}>
                  <button
                    onClick={() => navigate(category.path)}
                    className="text-gray-700 hover:underline"
                  >
                    {category.name}
                  </button>
                </li>
              ))}
            </ul>
          </motion.div>

          {/* Get in Touch */}
          <motion.div variants={itemVariants} className="space-y-4">
            <h4 className="text-lg font-semibold">Get in Touch...</h4>
            <hr className="border-gray-400 w-20" />
            <div className="space-y-2">
              {/* Find Us on Google Maps */}
              <div className="flex items-center gap-2">
                <FaMapMarkerAlt className="text-blue-500 w-6 h-6" />
                <button
                  onClick={() =>
                    window.open(
                      "https://maps.app.goo.gl/q7UwzN2VBZxPztJi9",
                      "_blank"
                    )
                  }
                  className="text-gray-700 hover:underline text-left"
                >
                  Find Us On Google Maps
                </button>
              </div>
              {/* Phone Numbers */}
              <div className="flex items-center gap-2">
                <FaPhoneAlt className="text-blue-500 w-6 h-6" />
                <span className="text-gray-700">
                  +91-7082210770, +91-7082210771
                </span>
              </div>
              {/* Email Address */}
              <div className="flex items-center gap-2">
                <FaEnvelope className="text-blue-500 w-6 h-6" />
                <span className="text-gray-700">info@nuvomedsurg.in</span>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </NuvoContainer>

      {/* Bottom Bar */}
      <div className="bg-black text-white text-sm px-6 py-4 flex flex-col md:flex-row justify-between items-center">
        <p>© 2024 Nuvo Medsurg Pvt.ltd. All Rights Reserved.</p>
        <div className="flex flex-row space-x-4 mt-2 md:mt-0">
          <button
            onClick={() => navigate("/terms")}
            className="hover:underline"
          >
            Terms and Conditions
          </button>
          <span>|</span>
          <button
            onClick={() => navigate("/privacy")}
            className="hover:underline"
          >
            Privacy Policy
          </button>
        </div>
      </div>
    </footer>
  );
};
