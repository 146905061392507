export const HomePageSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Nuvo Medsurg Pvt. Ltd.",
    "image": "https://www.nuvomedsurg.com/static/media/novoLogo.49f5a6d9d6b7e6439fce7efae2a8a597.svg",
    "@id": "",
    "url": "https://www.nuvomedsurg.com/",
    "telephone": "70822 10771",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "HSIIDC, 104, Sector 16",
      "addressLocality": "Bahadurgarh",
      "postalCode": "124507",
      "addressCountry": "IN"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 28.70890701510079,
      "longitude": 76.8873608664789
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Thursday",
        "Wednesday",
        "Friday",
        "Saturday"
      ],
      "opens": "09:00",
      "closes": "06:00"
    },
    "sameAs": "https://www.linkedin.com/company/nuvomedsurg/" 
  }
  
  export const FAQPageSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What are surgical sutures, and why are they important?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Surgical sutures are specialized medical devices used to close wounds or surgical incisions by stitching tissues together. They play a critical role in wound healing by providing support, preventing infections, and ensuring proper tissue alignment. Sutures can be absorbable or non-absorbable, depending on whether they dissolve in the body or need to be removed manually. India has emerged as a hub for surgical suture manufacture, offering high-quality products for both domestic and international markets."
        }
      },
      {
        "@type": "Question",
        "name": "What types of surgical sutures are commonly manufactured in India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "India produces a wide range of surgical sutures, including absorbable sutures (e.g., catgut, polyglycolic acid (PGA), and polydioxanone (PDO) sutures) which dissolve naturally in the body, and non-absorbable sutures (e.g., nylon, silk, and polypropylene), which are ideal for long-term wound support and require manual removal. Indian manufacturers cater to diverse medical needs, including general surgery, orthopedics, and cardiovascular procedures."
        }
      },
      {
        "@type": "Question",
        "name": "Who are the leading surgical suture manufacturers in India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Several reputable companies are recognized for their expertise in surgical suture manufacture in India. Nuvo Medsurg stands out as a trusted name, known for its commitment to precision, quality, and innovation. Offering products with ISO 13485 and CE certifications, Nuvo Medsurg provides cost-effective solutions that meet international standards. Its products are widely used by healthcare professionals across hospitals, clinics, and surgical centers worldwide."
        }
      },
      {
        "@type": "Question",
        "name": "What quality standards do surgical suture manufacturers in India follow?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Indian surgical suture manufacturers adhere to rigorous quality control measures and global standards, including ISO 13485 certification, FDA approvals for exports to regulated markets, and CE marking for compliance with European Union safety standards. These certifications highlight India's leadership in surgical suture manufacturing."
        }
      },
      {
        "@type": "Question",
        "name": "Are Indian surgical sutures exported globally?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Indian surgical sutures are widely exported to countries across the USA, Europe, Africa, and Southeast Asia. Manufacturers leverage India’s cost-effective production capabilities while maintaining world-class quality, offering a variety of absorbable and non-absorbable sutures."
        }
      },
      {
        "@type": "Question",
        "name": "How are surgical sutures classified based on usage?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Sutures are classified as absorbable or non-absorbable based on their usage and material properties. Absorbable sutures dissolve within the body and are commonly used for internal tissues (e.g., catgut and polyglycolic acid), while non-absorbable sutures (e.g., silk, nylon, and stainless steel) are used for external wounds and require removal. Indian manufacturers offer both monofilament and braided options tailored to specific surgical needs."
        }
      },
      {
        "@type": "Question",
        "name": "What materials are used for manufacturing surgical sutures in India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Indian manufacturers use a diverse range of materials, including natural materials (e.g., silk and catgut) and synthetic materials (e.g., nylon, polypropylene, polyglycolic acid (PGA), and stainless steel). These materials ensure durability, strength, and minimal tissue reaction, essential for successful surgeries."
        }
      },
      {
        "@type": "Question",
        "name": "What advancements are Indian manufacturers making in surgical sutures?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Indian manufacturers are driving innovation through advancements such as antimicrobial sutures (coated with agents to prevent infections), barbed sutures (for faster wound closure), and biodegradable materials (offering eco-friendly alternatives). These innovations enhance India’s reputation as a leader in surgical suture manufacturing."
        }
      },
      {
        "@type": "Question",
        "name": "Why are Indian surgical sutures cost-effective compared to other countries?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "India’s surgical suture manufacturers benefit from low production costs, skilled labor, and advanced manufacturing technologies. These factors enable them to offer high-quality products at competitive prices, making Indian sutures a preferred choice for hospitals and clinics worldwide."
        }
      },
      {
        "@type": "Question",
        "name": "How do I choose the right surgical suture manufacturer in India?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "When selecting a surgical suture manufacturer in India, consider certifications (e.g., ISO 13485, CE marking, and FDA approvals), product range (availability of absorbable and non-absorbable sutures), and reputation (customer reviews and industry experience). India’s expertise ensures a reliable supply of quality products for diverse medical needs."
        }
      }
    ]
  };
  