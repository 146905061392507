import { useNavigate } from "react-router-dom";
import { SingleProductType } from "../../types/product.types";
import { getMinAndMaxPrice } from "../../utils/getMinAndMaxPrice";

export const SimilarProducts = ({
  productsData,
  selectedProduct,
}: {
  productsData: SingleProductType[];
  selectedProduct: string;
}) => {
  const navigate = useNavigate();

  const filteredProductsData = productsData.filter(
    (product) => product.slug !== selectedProduct
  );

  const sortedProductsData = filteredProductsData.filter(
    (p) => p.category.name === "SURGICAL SUTURES"
  );

  // const sortedProductsData = filteredProductsData.sort((a, b) => {
  //   const aMinPrice = Math.min(
  //     ...a.variants.map((v) => v.codes.map((c) => c.discountedPrice)).flat()
  //   );
  //   const bMinPrice = Math.min(
  //     ...b.variants.map((v) => v.codes.map((c) => c.discountedPrice)).flat()
  //   );
  //   return aMinPrice - bMinPrice;
  // });

  return (
    <div className="flex flex-col gap-5 pb-10">
      <p className="text-title-6 md:text-title-5 font-semibold">
        Browse Our Top Selling Products
      </p>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {sortedProductsData &&
          sortedProductsData.slice(0, 4).map((product) => {
            const { maxPrice, minPrice } = getMinAndMaxPrice(product.variants);
            return (
              <div
                key={product.slug}
                className="cursor-pointer bg-nuvo-card items-center h-fit w-full  p-4 flex flex-col rounded-[8px]"
                onClick={() => {
                  navigate(`/categories/surgical-sutures/${product.slug}`);
                  window.scrollTo(0, 0);
                }}
              >
                <div className="flex-shrink-0 h-[200px] md:h-[300px] flex justify-center items-center">
                  <img
                    className="w-10/12 h-10/12 object-contain"
                    src={product.image}
                    alt={product.name}
                  />
                </div>
                <div className="row-span flex flex-col justify-between gap-10 flex-grow mt-3 md:mt-0 ">
                  <div>
                    <p className="font-medium text-title-7 md:text-title-6">
                      {product.name.length > 20
                        ? product.name.slice(0, 20) + "..."
                        : product.name}
                    </p>
                    <div className="flex flex-row gap-4">
                      <p className="font-medium text-title-7">₹ {minPrice}</p>-
                      <p className="font-medium text-title-7">₹ {maxPrice}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
