// src/components/SignUpForm.tsx

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { signUpUser } from "../../handlers/userHandler";
import { toast } from "react-hot-toast";
import { Dispatch, SetStateAction, useState } from "react";
import { countryCode } from "../../utils/countryCode";
import { useNavigate } from "react-router-dom";
import { useNuvoContext } from "../../context/NuvoContext";

export type SignUpInitialValuesType = {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  countryCode: string;
  GST: string | null;
};

type SignUpFormProps = {
  onSwitch: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const SignUpForm = ({ onSwitch, setIsOpen }: SignUpFormProps) => {
  const { setRender } = useNuvoContext();
  const [filteredCountryCode, setFilteredCountryCode] = useState(countryCode);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [isPhoneNumberEnabled, setIsPhoneNumberEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSelectedCountryCode(searchTerm);

    const filtered = countryCode.filter(
      (country) =>
        country.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        country.dial_code.includes(searchTerm)
    );
    setFilteredCountryCode(filtered);
    setShowDropdown(true);
  };

  const handleSelect = (
    dialCode: string,
    code: string,
    setFieldValue: Function
  ) => {
    setSelectedCountryCode(dialCode);
    setShowDropdown(false);
    setIsPhoneNumberEnabled(true);
    setFieldValue("countryCode", code);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email Address is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{7,15}$/, "Enter a valid phone number")
      .required("Phone Number is required"),
    GST: Yup.string().nullable(),
  });

  return (
    <div className="rounded-md shadow-md bg-white w-11/12 mx-auto p-5 mt-5">
      <Formik
        initialValues={{
          name: "",
          email: "",
          password: "",
          phoneNumber: "",
          countryCode: selectedCountryCode,
          GST: null,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setIsSubmitting(true);
          try {
            const data = await signUpUser(
              values.name,
              values.email,
              values.password,
              values.phoneNumber,
              values.countryCode,
              values.GST || undefined
            );
            if (data.responseCode === "SUCCESS") {
              toast.success("User Registered Successfully");
              localStorage.setItem("nuvo_user_cred", JSON.stringify(data));
              window.location.reload();
              setRender((prev) => !prev);
              resetForm();
              setIsOpen(false);
            } else if (data.responseCode === "PHONE_NUMBER_ALREADY_EXIST") {
              toast.error("User already exists with this Phone Number");
            } else if (data.responseCode === "EMAIL_ALREADY_EXIST") {
              toast.error("User already exists with this Email ID");
            } else {
              toast.error("Something went wrong. Please try again.");
            }
          } catch (error) {
            toast.error("An unexpected error occurred");
          } finally {
            setIsSubmitting(false);
            setSubmitting(false);
          }
        }}
      >
        {({ setFieldValue }) => (
          <Form className="space-y-6">
            {/* Logo */}
            {/* <div className="flex justify-center mb-4">
              <img src={Logo} className="w-16 h-16" alt="Sign Up Logo" />
            </div> */}

            {/* Name */}
            <div className="flex flex-col">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <div className="mt-1">
                <Field
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Enter your full name"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Email */}
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <Field
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter your email address"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Password */}
            <div className="flex flex-col">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <Field
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Create a password"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Phone Number */}
            <div className="flex flex-col">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <div className="mt-1 flex">
                {/* Country Code Selector */}
                <div className="relative w-1/3 mr-2">
                  <input
                    type="text"
                    name="countryCode"
                    id="countryCode"
                    value={selectedCountryCode}
                    onClick={() => setShowDropdown(!showDropdown)}
                    onChange={handleFilter}
                    placeholder="Code"
                    className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm cursor-pointer bg-white"
                    autoComplete="off"
                    aria-haspopup="listbox"
                    aria-expanded={showDropdown}
                  />
                  {/* Dropdown */}
                  {showDropdown && (
                    <ul
                      className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-y-auto"
                      role="listbox"
                    >
                      {filteredCountryCode.length > 0 ? (
                        filteredCountryCode.map((country) => (
                          <li
                            key={country.code}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                            onClick={() => {
                              handleSelect(
                                country.dial_code,
                                country.code,
                                setFieldValue
                              );
                            }}
                            role="option"
                            aria-selected={
                              country.dial_code === selectedCountryCode
                            }
                          >
                            {/* Uncomment and ensure you have flag URLs */}
                            {/* <img
                              src={country.flag}
                              alt={`${country.name} flag`}
                              className="w-5 h-5 mr-2"
                            /> */}
                            <span>
                              {country.name} ({country.dial_code})
                            </span>
                          </li>
                        ))
                      ) : (
                        <li className="px-4 py-2 text-gray-500">
                          No countries found.
                        </li>
                      )}
                    </ul>
                  )}
                </div>

                {/* Phone Number Input */}
                <div className="flex-1">
                  <Field
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    placeholder="Enter your phone number"
                    className={`block w-full px-3 py-2 border ${
                      !isPhoneNumberEnabled
                        ? "bg-gray-100 cursor-not-allowed"
                        : "bg-white"
                    } border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                    disabled={!isPhoneNumberEnabled}
                    aria-disabled={!isPhoneNumberEnabled}
                  />
                </div>
              </div>
              <ErrorMessage
                name="phoneNumber"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* GST */}
            <div className="flex flex-col">
              <label
                htmlFor="GST"
                className="block text-sm font-medium text-gray-700"
              >
                GST (Optional)
              </label>
              <div className="mt-1">
                <Field
                  id="GST"
                  name="GST"
                  type="text"
                  placeholder="Enter your GST number"
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <ErrorMessage
                name="GST"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                  isSubmitting
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
              >
                {isSubmitting ? (
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v8H4z"
                    ></path>
                  </svg>
                ) : (
                  "Sign Up"
                )}
              </button>
            </div>

            {/* Switch to Log In */}
            <div className="text-center mt-4">
              <p className="text-sm text-gray-600">
                Already have an account?{" "}
                <button
                  type="button"
                  className="text-blue-600 hover:text-blue-500 font-medium"
                  onClick={onSwitch}
                >
                  Log In
                </button>
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
