import { useEffect, useState } from "react";
import { SingleCartItemType } from "../../../types/cart.types";
import { getLocalStorage, isAuth } from "../../../helpers/authHelper";
import { useNuvoContext } from "../../../context/NuvoContext";
import { Tooltip } from "react-tooltip";
import baseUrl from "../../../handlers/baseUrl";
import toast from "react-hot-toast";
import { createOrder } from "../../../handlers/orderHandler";
import { numberToINR } from "../../../utils/numberToInr";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../../context/NuvoCartContext";
import { useFormikContext } from "formik";
import { addToCart } from "../../../handlers/cartHandler";
import { LoginResponse } from "../../../types/user.types";
import { createPayment } from "../../../handlers/paymentHandler";

export type ProcessedCartItemType = {
  product: string;
  code: string;
  size: string;
  quantity: number;
};

export const CartRightPanel = ({ data }: { data: SingleCartItemType[] }) => {
  const [loading, setIsLoading] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const handleNavigate = useNavigate();
  const [productQuantityData, setProductQuantityData] = useState<{
    [key: string]: number;
  }>({});

  const { values } = useFormikContext<{
    deliveryAddress: string | null;
    paymentType: "ONLINE" | "COD";
  }>();

  const { user, render } = useNuvoContext();
  const { handleError } = useNuvoContext();
  const { updateCartItemsCount } = useCart();

  const associatedProducts = data.flatMap((product) => {
    const quantity = product.quantity;
    return product.data.variants.flatMap((variant) =>
      variant.size === product.size
        ? variant.codes
            .filter((code) => code.shortCode === product.code)
            .map((code) => ({
              ...code,
              quantity,
            }))
        : []
    );
  });

  const cartTotal = associatedProducts.reduce(
    (acc, product) => acc + product.discountedPrice * product.quantity,
    0
  );

  const cartOriginalTotal = associatedProducts.reduce(
    (acc, product) => acc + product.originalPrice * product.quantity,
    0
  );

  const totalDiscount = cartOriginalTotal - cartTotal;

  useEffect(() => {
    if (data) {
      const newProductQuantityData: { [key: string]: number } = {};
      data.forEach((el) => {
        newProductQuantityData[el.data._id] = el.quantity;
      });
      setProductQuantityData(newProductQuantityData);
    }
  }, [data]);

  const handlePaymentNew = async () => {
    setIsLoading(true);
    try {
      let processedArray: ProcessedCartItemType[] = [];
      data.forEach((d) =>
        processedArray.push({
          code: d.code,
          product: d.id,
          size: d.size,
          quantity: d.quantity,
        })
      );

      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");

      if (user && user.token) {
        const data = await addToCart(processedArray, user.token);
        if (data.cartId && values.deliveryAddress) {
          if (values?.paymentType === "ONLINE") {
            const orderResponse = await createOrder(
              data.cartId,
              "ONLINE",
              values.deliveryAddress
            );
            if (orderResponse.orderId) {
              const razorpayOrderData = await createPayment(
                Math.round(cartTotal),
                "INR"
              );

              handleVerifyPayment({
                amount: Math.round(cartTotal),
                currency: "INR",
                id: razorpayOrderData?.id,
                order_id: orderResponse.orderId,
                cartId: data.cartId,
              });
            }
          }
          if (values?.paymentType === "COD") {
            const orderResponse = await createOrder(
              data.cartId,
              "COD",
              values.deliveryAddress,
              cartTotal
            );
            if (orderResponse.orderId) {
              toast.success("Order Placed Successfully");
              localStorage.removeItem("nuvo_cart");
              handleNavigate("/dashboard");
              updateCartItemsCount();
            }
          }
        }
      }
    } catch (error) {
      handleError(error);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkAuth = async () => {
    const isAuthenticated = await isAuth();
    setIsAuthenticated(isAuthenticated);
  };
  useEffect(() => {
    checkAuth();
  }, []);

  const handleVerifyPayment = async (orderData: {
    amount: number;
    currency: string;
    id: string;
    order_id: string;
    cartId: string;
  }) => {
    const RAZORPAY_KEY = process.env.REACT_APP_RAZORPAY_KEY_DEVELOPMENT;
    const options = {
      key: RAZORPAY_KEY,
      amount: orderData.amount * 100,
      currency: orderData.currency,
      name: "Novo Medsurg",
      description: "Nuvo Medsurg , novomedsurg.com",
      order_id: orderData.id,
      handler: async (response: {
        razorpay_order_id: string;
        razorpay_payment_id: string;
        razorpay_signature: string;
      }) => {
        try {
          const res = await fetch(`${baseUrl}/payment/verify`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              razorpay_order_id: orderData.id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              amount: orderData.amount,
              currency: orderData.currency,
              orderId: orderData.order_id,
              cartId: orderData.cartId,
            }),
          });

          const verifyData = await res.json();
          if (verifyData.message) {
            toast.success(verifyData.message);
            localStorage.removeItem("nuvo_cart");

            // await sendConfirmationEmail(
            //   response,
            //   orderData.cart,
            //   user,
            //   cartTotal
            // );

            handleNavigate("/dashboard");
            updateCartItemsCount();
          }
        } catch (error) {
          handleError(error);
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      },
      theme: {
        color: "#5f63b8",
      },
      modal: {
        ondismiss: async function () {
          const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
          const res = await fetch(`${baseUrl}/payment/cancelled`, {
            method: "POST",
            headers: {
              "content-type": "application/json",
              Authorization: "Bearer " + user?.token,
            },
            body: JSON.stringify({
              orderId: orderData.order_id,
            }),
          });
          setIsLoading(false);
        },
      },
    };
    console.log(options, "options");
    const rzp1 = new (window as any).Razorpay(options);
    console.log(rzp1, "rzp1");
    rzp1.open();
  };

  return (
    <>
      {data && (
        <div className="shadow-md rounded-md bg-white p-4">
          <p className="text-black font-bold text-sm md:text-lg">
            Bill Summary
          </p>
          <div className="flex flex-col gap-1 md:gap-3 text-black mt-2 md:mt-10">
            <div className="flex flex-row  text-sm md:text-md justify-between">
              <p>Total Price:</p>
              <p>{numberToINR(Number(cartOriginalTotal.toFixed(2)))}</p>
            </div>
            <div className="flex flex-row text-sm md:text-md justify-between font-semibold md:mt-1">
              <p>Total Amount To Be Paid:</p>
              <p>{numberToINR(Number(cartTotal.toFixed(2)))}</p>
            </div>
          </div>
          <div className="mt-20 flex flex-col gap-1">
            {/* <SelectPaymentType /> */}
            <p className="text-danger text-md md:text-md">
              ★ Note : <span className="text-black">All Taxes Included</span>
            </p>
            <button
              className={` py-2 px-10 w-full text-sm md:text-lg font-medium rounded-[8px] ${
                isAuthenticated && values.deliveryAddress
                  ? "cursor-pointer text-white bg-blue-500"
                  : "cursor-not-allowed bg-gray-100 text-gray-600"
              }`}
              disabled={!isAuthenticated || !values.deliveryAddress}
              onClick={handlePaymentNew}
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                !isAuthenticated
                  ? "Login to proceed"
                  : !values.deliveryAddress
                  ? "Select a delivery address to proceed"
                  : undefined
              }
            >
              {loading ? (
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 m-auto text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Proceed To Buy"
              )}
            </button>
          </div>

          <Tooltip id="my-tooltip" />
        </div>
      )}
    </>
  );
};
