import React, { ReactNode } from "react";

interface FieldGroupRenderProps {
  title?: string;
  rightElement?: ReactNode;
  children: ReactNode;
  buttonElements?: ReactNode[];
  className?: string;
}

const FieldGroupRender: React.FC<FieldGroupRenderProps> = ({
  title,
  rightElement,
  children,
  buttonElements,
  className,
}) => {
  return (
    <div
      className={`w-full p-4 rounded-lg shadow-sm bg-white mb-4 border border-gray-300 ${className}`}
    >
      {(title || rightElement) && (
        <div className="flex justify-between items-center mb-4">
          {title && <h2 className="text-xl font-semibold">{title}</h2>}
          {rightElement && <div>{rightElement}</div>}
        </div>
      )}
      <div className="content">{children}</div>
      {buttonElements && buttonElements.length > 0 && (
        <div className="flex justify-end mt-4 space-x-2">
          {buttonElements.slice(0, 3).map((button, index) => (
            <div key={index}>{button}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FieldGroupRender;
