// src/components/UserOrders.tsx

import React from "react";
import {
  FaCheckCircle,
  FaBoxOpen,
  FaShippingFast,
  FaTimesCircle,
} from "react-icons/fa";
import { UserOrderData } from "../../../../types/userOrder.types";
import FieldGroupRender from "../../../shared/core/fieldGroupRender";
import { EmptyState } from "../../../shared/empty-states/EmptyState";

interface UserOrdersProps {
  data: UserOrderData;
}

const statusIcons: { [key: string]: JSX.Element } = {
  DELIVERED: <FaCheckCircle className="text-green-500" />,
  SHIPPED: <FaShippingFast className="text-blue-500" />,
  ORDERED: <FaBoxOpen className="text-yellow-500" />,
  CANCELLED: <FaTimesCircle className="text-red-500" />,
};

const UserOrders: React.FC<UserOrdersProps> = ({ data }) => {
  const { orders } = data;

  return (
    <div className="container mx-auto">
      <div className="h-[85vh] overflow-y-auto scrollbar-hide">
        {orders.map((order) => {
          console.log(order);
          return (
            <FieldGroupRender title={`Order ID: ${order.orderId}`}>
              <div>
                <div key={order.orderId} className="border-b pb-4 mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <div>
                      <p className="text-gray-600">Order Date : {order.date}</p>
                    </div>
                    <div className="flex items-center space-x-2">
                      {statusIcons[order.status] || (
                        <FaBoxOpen className="text-gray-500" />
                      )}
                      <span className="font-semibold">{order.status}</span>
                    </div>
                  </div>
                  <hr className="my-4 w-11/12 mx-auto" />
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                    {/* Order Items */}
                    <div className="md:col-span-2">
                      {order.items.map((item, index) => (
                        <div
                          key={index}
                          className="flex gap-5 items-center mb-4"
                        >
                          <img
                            src={item.image}
                            alt={item.productName}
                            className="w-1/6 h-1/6"
                          />
                          <div>
                            <p className="font-medium">{item.productName}</p>
                            <div className="flex gap-2 items-center">
                              <p className="text-gray-400">
                                Quantity: {item.quantity}
                              </p>
                              <div className="w-1 h-1 bg-gray-500 rounded-full"></div>
                              <p className="text-gray-400">{item.size}</p>
                              <div className="w-1 h-1 bg-gray-500 rounded-full"></div>

                              <p className="text-gray-400">{item.code}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <p className="text-lg text-gray-600 font-semibold">
                    Total: ₹{order?.totalAmount?.toFixed(2)}
                  </p>

                  <div>
                    <p className="text-lg text-gray-600 font-semibold">
                      Delivery Address
                    </p>

                    <div key={order.address._id} className="mb-2 text-gray-400">
                      <p>
                        {order.address.doorNo}, {order.address.streetName}
                      </p>
                      <p>
                        {order.address.city}, {order.address.state} -{" "}
                        {order.address.pincode}
                      </p>
                      {order.address.landMark && (
                        <p>
                          <strong>Landmark:</strong> {order.address.landMark}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </FieldGroupRender>
          );
        })}
        {orders.length === 0 && (
          <EmptyState title={"No orders found"} type="ORDERS"></EmptyState>
        )}
      </div>
    </div>
  );
};

export default UserOrders;
