// src/components/SearchResults.tsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { Product } from "../../hooks/useProductSearch";
import { slugify } from "../../utils/Slugify";

interface SearchResultsProps {
  results: Product[];
  onClose: () => void;
  query: string; // To highlight search terms
}

const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  onClose,
  query,
}) => {
  const navigate = useNavigate();

  if (results.length === 0) {
    return (
      <div className="mt-2 text-center text-gray-500">
        <p>No products found.</p>
      </div>
    );
  }

  // Function to highlight the search query in product names
  const highlightText = (text: string, highlight: string) => {
    if (!highlight) return text;
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part, i) =>
          regex.test(part) ? (
            <span key={i} className="bg-yellow-200">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </>
    );
  };

  return (
    <ul className="absolute top-full left-0 w-full max-h-80 overflow-y-auto bg-white border-b border-gray-200 rounded-b-md shadow-lg z-50">
      {results.map((product) => (
        <li
          key={product.slug}
          className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
          onClick={() => {
            navigate(
              `/categories/${slugify(product.category.name)}/${product.slug}`
            ); // Adjust the route as needed
            onClose();
          }}
        >
          <img
            src={product.image} // Ensure your Product type has 'image'
            alt={product.name}
            className="w-10 h-10 object-cover rounded mr-3"
          />
          <span className="text-gray-700">
            {highlightText(product.name, query)}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default SearchResults;
