import { useEffect, useState } from "react";
import { SurturesSection } from "../../components/homepage/SurturesSection";
import { CategoryDataType } from "../../types/category.types";
import { getAllCategories } from "../../handlers/categoryHandler";
import { useNuvoContext } from "../../context/NuvoContext";
import { CategoriesNew } from "../../components/homepage/CategoriesNew";
import { WhyUs } from "../../components/homepage/WhyUs";
import Experience from "../../components/homepage/Experience";
import { AutoCarousel } from "../../components/ImageCarousel";
import { FirstSection } from "../../components/homepage/FirstSection";
import { WideRange } from "../../components/homepage/WideRange";
import { HomeCard } from "../../components/homepage/HomeCard";
import FAQSection from "../../components/new-ui/Faqs/FaqSection";
import { Featured } from "../../components/new-ui/Homepage/featured-section";
import Testimonials from "../../components/new-ui/Testimonials";
import JSONLD from "../../utils/seo-schema/seoSchema";
import { HomePageSchema } from "../../utils/seo-schema/seoSchemaData";
import { Helmet } from "react-helmet-async";
export const Homepage = () => {
  const [categoryData, setCategoryData] = useState<CategoryDataType | null>(
    null
  );

  const { handleError } = useNuvoContext();

  const getAllCategoriesData = async () => {
    try {
      const data = await getAllCategories();
      setCategoryData(data);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  useEffect(() => {
    getAllCategoriesData();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="w-full">
      <Helmet>
        <meta
          name="title"
          content="Surgical Suture Manufacturer in India | Trusted Surgical Suture
          Company"
        />
        <meta
          name="description"
          content="Leading surgical suture company and manufacturer in India, offering globally certified sutures for safe and efficient surgical procedures. Contact us now!"
        />

        <link rel="canonical" href="https://www.nuvomedsurg.com/" />
        <meta
          property="og:title"
          content="Surgical Suture Manufacturer in India | Trusted Surgical Suture Company"
        />
        <meta
          property="og:description"
          content="Leading surgical suture company and manufacturer in India, offering globally certified sutures for safe and efficient surgical procedures. Contact us now!."
        />
        <meta property="og:url" content="https://www.nuvomedsurg.com/" />
        <meta
          property="og:image"
          content="https://www.nuvomedsurg.com/static/media/novoLogo.49f5a6d9d6b7e6439fce7efae2a8a597.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Nuvo Medsurg" />
      </Helmet>
      <JSONLD data={HomePageSchema} />
      <AutoCarousel />
      <>
        <div className="w-full flex flex-col gap-6">
          <FirstSection />
          <SurturesSection />
          <hr className="border border-gray-100 w-11/12 mx-auto"></hr>
          {categoryData && <CategoriesNew data={categoryData} />}
          <hr className="border border-gray-100 w-11/12 mx-auto"></hr>
          <Featured />
          <hr className="border border-gray-100 w-11/12 mx-auto"></hr>

          <WideRange />

          <FAQSection />
          <Experience />
          <WhyUs />

          <HomeCard />

          {/* <IdealSuture /> */}
          <Testimonials />
        </div>
      </>
    </div>
  );
};
