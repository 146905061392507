import React from "react";

interface LabelProps {
  htmlFor: string;
  children: React.ReactNode;
  className?: string;
}

const Label: React.FC<LabelProps> = ({ htmlFor, children, className }) => {
  const newClassName = className
    ? className
    : "block text-sm font-medium text-gray-700 mb-1";
  return (
    <label htmlFor={htmlFor} className={newClassName}>
      {children}
    </label>
  );
};

export default Label;
