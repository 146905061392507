// src/components/Testimonials/Testimonials.tsx

import React, { useState, useEffect } from "react";
import { testimonials } from "../../services/testimonial.services";
import {
  FaQuoteLeft,
  FaQuoteRight,
  FaArrowLeft,
  FaArrowRight,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const Testimonials: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const length = testimonials.length;

  const nextTestimonial = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevTestimonial = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  // Auto-rotate testimonials every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextTestimonial();
    }, 5000);

    return () => clearInterval(interval);
  }, [current]);

  // Animation variants for testimonials
  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 300 : -300,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction < 0 ? 300 : -300,
      opacity: 0,
    }),
  };

  // Direction state to determine animation direction
  const [direction, setDirection] = useState(0);

  const handleNext = () => {
    setDirection(1);
    nextTestimonial();
  };

  const handlePrev = () => {
    setDirection(-1);
    prevTestimonial();
  };

  // Swipe handling
  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: {
      offset: { x: number; y: number };
      velocity: { x: number; y: number };
    }
  ) => {
    const swipeThreshold = 100; // Minimum swipe distance
    if (info.offset.x > swipeThreshold) {
      handlePrev();
    } else if (info.offset.x < -swipeThreshold) {
      handleNext();
    }
  };

  return (
    <section className="py-12 bg-gray-100">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl font-extrabold text-gray-900 mb-8 text-center">
          What Our Clients Say
        </h2>
        <div className="relative h-64 md:h-80">
          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              key={testimonials[current].id}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              className="absolute top-0 left-0 w-full h-[300px] flex flex-col justify-between bg-white shadow-lg rounded-lg p-6 "
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={handleDragEnd}
            >
              <div className="mb-4">
                <FaQuoteLeft className="text-indigo-500 text-xl mb-2" />
                <p className="text-gray-700 italic">
                  "{testimonials[current].description}"
                </p>
                <FaQuoteRight className="text-indigo-500 text-xl mt-2" />
              </div>
              <div className="flex items-center mt-4">
                {testimonials[current].avatar && (
                  <img
                    src={testimonials[current].avatar}
                    alt={testimonials[current].author}
                    className="w-12 h-12 rounded-full mr-4 object-cover"
                  />
                )}
                <div>
                  <p className="text-lg font-semibold text-gray-900">
                    {testimonials[current].author}
                  </p>
                </div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* Navigation Buttons */}
          <button
            onClick={handlePrev}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 text-indigo-500 p-2 rounded-full shadow-md focus:outline-none"
            aria-label="Previous Testimonial"
          >
            <FaArrowLeft />
          </button>
          <button
            onClick={handleNext}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white bg-opacity-75 hover:bg-opacity-100 text-indigo-500 p-2 rounded-full shadow-md focus:outline-none"
            aria-label="Next Testimonial"
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
