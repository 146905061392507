import { Form, Formik, useFormikContext } from "formik";
import TextInput from "../../../shared/core/textInput";
import * as Yup from "yup";
import { LoginResponse, UserAddress } from "../../../../types/user.types";
import { createAddressHandler } from "../../../../handlers/addressHandler";
import { getLocalStorage } from "../../../../helpers/authHelper";
import toast from "react-hot-toast";
import { Dispatch, SetStateAction } from "react";

const validationSchema = Yup.object().shape({
  doorNo: Yup.string().required("Door number is required"),
  streetName: Yup.string().required("Street name is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .matches(/^\d{6}$/, "Invalid pincode format")
    .required("Pincode is required"),
  landMark: Yup.string().nullable(),
  type: Yup.string()
    .oneOf(["Office", "Personal"], "Invalid address type")
    .required("Address type is required"),
});

export const AddAddressView = ({
  setShowAddAddress,
}: {
  setShowAddAddress: Dispatch<SetStateAction<Boolean>>;
}) => {
  return (
    <div className="w-full mx-auto py-4">
      <div className="bg-white mt-6 border p-4 rounded-md shadow-md">
        <h2 className="text-lg font-medium mb-4">Add New Address</h2>
        <Formik
          initialValues={{
            doorNo: "",
            streetName: "",
            city: "",
            state: "",
            pincode: "",
            landMark: "",
            type: "Personal",
          }}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form className="space-y-4">
              <TextInput
                id="doorNo"
                name="doorNo"
                label="Door No"
                value={values.doorNo}
                onChange={handleChange}
                placeholder="Enter Door No"
              />
              {touched.doorNo && errors.doorNo && (
                <p className="text-red-500 text-sm">{errors.doorNo}</p>
              )}
              <TextInput
                id="streetName"
                name="streetName"
                label="Street Name"
                value={values.streetName}
                onChange={handleChange}
                placeholder="Enter Street Name"
              />
              {touched.streetName && errors.streetName && (
                <p className="text-red-500 text-sm">{errors.streetName}</p>
              )}
              <TextInput
                id="city"
                name="city"
                label="City"
                value={values.city}
                onChange={handleChange}
                placeholder="Enter City"
              />
              {touched.city && errors.city && (
                <p className="text-red-500 text-sm">{errors.city}</p>
              )}
              <TextInput
                id="state"
                name="state"
                label="State"
                value={values.state}
                onChange={handleChange}
                placeholder="Enter State"
              />
              {touched.state && errors.state && (
                <p className="text-red-500 text-sm">{errors.state}</p>
              )}
              <TextInput
                id="pincode"
                name="pincode"
                label="Pincode"
                value={values.pincode}
                onChange={handleChange}
                placeholder="Enter Pincode"
              />
              {touched.pincode && errors.pincode && (
                <p className="text-red-500 text-sm">{errors.pincode}</p>
              )}
              <TextInput
                id="landMark"
                name="landMark"
                label="Landmark"
                value={values.landMark}
                onChange={handleChange}
                placeholder="Enter Landmark"
              />
              <div>
                <label htmlFor="type">Type</label>
                <select
                  id="type"
                  name="type"
                  value={values.type}
                  onChange={handleChange}
                  className="block w-full mt-1 border border-gray-300 rounded"
                >
                  <option value="Personal">Personal</option>
                  <option value="Office">Office</option>
                </select>
              </div>
              <AddAddressFormSubmit setShowAddAddress={setShowAddAddress} />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export const AddAddressFormSubmit = ({
  setShowAddAddress,
}: {
  setShowAddAddress: Dispatch<SetStateAction<Boolean>>;
}) => {
  const { values } = useFormikContext<UserAddress>();

  const handleAddAddress = async (newAddress: UserAddress) => {
    try {
      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
      if (user && user.token) {
        // Assuming createAddressHandler returns the new address object
        const createdAddress = await createAddressHandler(
          newAddress.streetName,
          newAddress.doorNo,
          newAddress.city,
          newAddress.state,
          newAddress.pincode,
          user.token,
          newAddress.landMark,
          newAddress.type
        );
        if (createdAddress) {
          toast.success("Address added successfully");
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Failed to add address:", error);
      toast.error("Failed to add address");
    }
  };

  return (
    <div className="flex gap-4">
      <button
        type="button"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        onClick={() => {
          handleAddAddress(values);
          setShowAddAddress(false);
        }}
      >
        Submit
      </button>
      <button
        type="button"
        className="w-full bg-gray-300 text-black py-2 px-4 rounded hover:bg-gray-400"
        onClick={() => {
          setShowAddAddress(false);
        }}
      >
        Cancel
      </button>
    </div>
  );
};
