import {
  divClassName,
  h1ClassName,
  h2ClassName,
} from "../../single-category-css";

export const DisposableSectionOne = () => {
  return (
    <div className="flex flex-col text-justify w-11/12 mx-auto gap-10">
      <h1 className={h1ClassName}>Buy Surgical Disposable Online in India</h1>
      <div className={divClassName}>
        <p className="text-sm md:text-base">
          Surgical disposables are essential for healthcare facilities to
          maintain hygienic conditions and patient safety throughout surgical
          procedures. Single-use goods like gowns, drapes, masks, and gloves
          stop infections and the transmission of diseases. Medical
          professionals safeguard patients and staff by using excellent surgical
          disposables to maintain a sterile environment. Nuvo Medsurg provides
          diverse surgical disposables that fulfil strict international
          requirements. Our disposables work well for various medical procedures
          since they are made with comfort and dependability in consideration.
          Our products perform effectively, whether you require medical gloves
          for precise tasks or masks for respiratory protection. As India's
          premier surgical disposable makers, we focus on quality and
          innovation. Our products use cutting-edge technology and materials to
          provide the most significant levels of safety. Trust Nuvo Medsurg for
          all of your surgical disposable needs and keep your medical practices
          safe, sanitary, and efficient.
        </p>
      </div>
      <div className={divClassName}>
        <h2 className={h2ClassName}>Surgical Disposable Products type</h2>
        <p className="text-sm md:text-base">
          We provide an extensive selection of disposable surgical items for
          optimal effectiveness, safety, and cleanliness in medical settings.
          Surgical disposable goods from Nuvo Medsurg are essential in every
          healthcare environment since they are designed to meet the most
          significant safety requirements.
        </p>
        <ul className="list-disc ml-10 text-sm md:text-base">
          <li>
            <strong> Surgical Gloves: </strong>Surgical gloves are vital for
            preserving sterility. They provide contamination protection and a
            secure environment for patients and medical personnel.
          </li>
          <li>
            <strong>Surgical masks:</strong> These masks lower the risk of
            infection by shielding the wearer from airborne particles. They are
            essential for safeguarding your lungs during operations.
          </li>
          <li>
            <strong>Surgical Gowns:</strong> Surgical gowns are crucial
            protective clothes healthcare personnel use during surgical
            operations. These robes act as an infection barrier, keeping the
            medical personnel and the patient safe from contamination. Our gowns
            are intended to exceed stringent safety requirements, providing
            dependable covering and freedom of mobility during procedures.
          </li>
          <li>
            <strong>Surgical Drapes:</strong> Surgical drapes keep bacteria and
            other pollutants at bay by establishing a sterile environment around
            the surgical site. Nuvo Medsurg's surgical drapes are made from
            high-quality absorbent fabrics that protect fluids and pollutants.
          </li>
          <li>
            <strong>Disposable Caps and Shoe Covers</strong> Disposable caps and
            shoe coverings are essential for infection control in medical
            environments. Nuvo Medsurg provides healthcare personnel with
            high-quality disposable hats and shoe coverings as additional
            protection. Both items are built of durable, lightweight products
            that provide comfort and simplicity while adhering to hygiene and
            safety regulations.
          </li>
        </ul>
      </div>
    </div>
  );
};
