import React from "react";

export const HomeCard = () => {
  const data = [
    {
      title: "Precision and Excellence",
      description:
        "Nuvo Medsurg sets the standard for precision and perfection in surgical sutures. Our equipment offers unparalleled accuracy and durability, resulting in more successful surgeries. Each suture goes through thorough quality testing, proving our dedication to exceptional performance and patient security—Trust Nuvo Medsurg for exceptional surgical solutions.",
    },
    {
      title: "Quick Delivery",
      description:
        "Nuvo Medsurg guarantees quick delivery of surgical sutures to meet urgent medical demands. Our streamlined supply network allows us to fulfill orders across India quickly. You can count on us to deliver quick and efficient service, ensuring you always have the premium sutures you require when needed.",
    },
    {
      title: "Customization",
      description:
        "Nuvo Medsurg serves as unique surgical sutures that fit your requirements. We provide a variety of sizes, materials, and designs to ensure maximum performance for each treatment. Our team of experts collaborates with you to develop the ideal solution, ensuring perfection and dependability in each stitch.",
    },
  ];

  return (
    <section className="w-11/12 mx-auto">
      {/* Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12 w-10/12 mx-auto">
        {data.map((card) => (
          <div
            key={card.title}
            className="bg-nuvo-card rounded-lg p-6 flex flex-col gap-4 shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <h3 className="text-lg md:text-xl font-semibold text-center text-gray-800">
              {card.title}
            </h3>
            <p className="text-sm md:text-base text-gray-600 text-justify">
              {card.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};
