import { useNavigate } from "react-router-dom";
import useWindowWidth from "../../hooks/useWindowWidth";

interface BreadCrumbType {
  label: string;
  link?: string;
}

interface BreadCrumbsProps {
  breadcrumbs: BreadCrumbType[];
}

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ breadcrumbs }) => {
  const navigate = useNavigate();
  const { isMd } = useWindowWidth();
  return (
    <div className="cursor-pointer gap-2 hidden md:flex md:flex-row">
      {breadcrumbs.map((breadcrumb, index) => (
        <div
          key={index}
          className={`flex ${
            !isMd && "text-body-6"
          } flex-row gap-2  text-black hover:underline`}
          onClick={() => breadcrumb.link && navigate(`${breadcrumb.link}`)}
        >
          <p
            className={`${index === breadcrumbs.length - 1 && "text-blue-400"}`}
          >
            {breadcrumb.label}
          </p>
          {index !== breadcrumbs.length - 1 && <RightChevron />}
        </div>
      ))}
    </div>
  );
};

export const RightChevron = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6 text-black"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};
