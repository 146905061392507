import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { LoginResponse } from "../../types/user.types";
import { useNuvoContext } from "../../context/NuvoContext";
import { FaUserAlt } from "react-icons/fa";
// import { MyProfileFlyIn } from "../loggedUser/ProfileFlyIn";

export default function UserButton({
  authStatus,
  setIsLoginOpen,
}: {
  authStatus: boolean;
  setIsLoginOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const { setRender, setUser, user } = useNuvoContext();
  const handleLogout = () => {
    localStorage.removeItem("nuvo_user_cred");
    window.location.reload();
    setRender((prev) => !prev);
    setUser(null);
    navigate("/");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div ref={dropdownRef} className="relative text-white">
      <div
        onClick={() => {
          if (!authStatus) {
            setIsLoginOpen(true);
          } else {
            setIsOpen(true);
          }
        }}
        className="flex items-center text-xl font-medium text-gray-700 hover:text-blue-500 px-3 py-2 rounded-md cursor-pointer"
      >
        <FaUserAlt size={20} />
        {authStatus && (
          <span className="ml-2">{user?.user.name.slice(0, 3)}</span>
        )}
      </div>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white rounded-md shadow-md text-black border border-gray-200 rounded shadow z-50">
          <ul className="list-none">
            <li
              onClick={() => {
                navigate("/dashboard");
              }}
              className="py-2 px-4 hover:bg-surface-grey hover:text-black cursor-pointer font-medium text-sm"
            >
              My Profile
            </li>
            <li
              className="py-2 px-4 hover:bg-surface-grey hover:text-black cursor-pointer font-medium text-sm"
              onClick={handleLogout}
            >
              Logout
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
