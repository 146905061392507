import axios from "axios";
import { MyOrderType } from "../types/orders.types";
// import { CategoryDataType } from "../types/category.types";
import baseUrl from "./baseUrl";
import { getLocalStorage } from "../helpers/authHelper";
import { LoginResponse } from "../types/user.types";

export const getAllOrders = async () => {
  const { data } = await axios.get(`${baseUrl}/order`);

  return data.data as MyOrderType[];
};

export type CreateOrderResponse = {
  status: string;
  message: string;
  orderId?: string;
};
export const createOrder = async (
  cartId: string,
  paymentType: "ONLINE" | "COD",
  addressId: string,
  orderAmount?: number
) => {
  const user: LoginResponse = getLocalStorage("nuvo_user_cred");

  const { data } = await axios.post(
    `${baseUrl}/order/create`,
    {
      cartId: cartId,
      paymentType: paymentType,
      addressId: addressId,
      orderAmount: orderAmount,
    },
    {
      headers: {
        Authorization: "Bearer " + user.token,
      },
    }
  );
  return data as CreateOrderResponse;
};

export const postOrder = async (options: any) => {
  const user: LoginResponse = getLocalStorage("nuvo_user_cred");
  const { data } = await axios.post(`${baseUrl}/payment/order`, options, {
    headers: {
      Authorization: "Bearer " + user.token,
    },
  });
  return data.data;
};

export const getMyOrders = async (bearerToken: string) => {
  const { data } = await axios.get(`${baseUrl}/order/getMyOrder`, {
    headers: {
      Authorization: "Bearer " + bearerToken,
    },
  });
  return data?.data;
};
