import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";

// Example motion variants (optional)
const fadeInFromLeft = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.6 },
  },
};

const fadeInFromRight = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.6 },
  },
};

const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.6 },
  },
};

export const WideRange = () => {
  const navigate = useNavigate();

  return (
    <div className="w-11/12 mx-auto flex flex-col gap-16 mt-4 md:mt-12">
      {/* First Section: Image Left, Text Right */}
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center"
        // Animate from left or up, your choice
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true /* animate once */ }}
      >
        {/* Image */}
        <LazyLoadImage
          className="w-10/12 mx-auto h-auto max-h-96 object-contain rounded-lg"
          alt="Wide Range of Surgical Sutures"
          src="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053310/pk1yuqxk7wwnrugeidsl.webp"
          loading="lazy"
          effect="blur"
        />

        {/* Text */}
        <div className="flex flex-col gap-6">
          <h2 className="text-lg font-semibold text-justify">
            We Offer a Wide Range of Surgical Sutures
          </h2>
          <p className="text-sm md:text-base text-slate-600 text-justify">
            Among surgical suture manufacturers in India, Nuvo Medsurg stands
            out for its superior products. We provide a wide selection of
            surgical sutures and ligatures to meet all your medical needs. Our
            medical sutures ensure precision and strength in every stitch,
            offering options ranging from absorbable to non-absorbable. Our
            commitment to quality and innovation ensures that healthcare
            professionals can rely on our products for safe and successful
            surgeries.
          </p>
        </div>
      </motion.div>

      {/* Second Section: Text Left, Image Right */}
      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-center"
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        {/* Text */}
        <div className="flex flex-col gap-6">
          <h2 className="text-lg font-semibold text-justify">
            We Supply All Types of Surgical Sutures
          </h2>
          <div className="text-sm md:text-base text-slate-600 text-justify flex flex-col gap-4">
            <p>
              Our surgical dressings provide enhanced protection and support for
              successful wound healing in various medical cases. Nuvo Medsurg
              offers a comprehensive range of medical-surgical instruments that
              complement our superior suture solutions. We provide a variety of
              surgical sutures, including absorbable, non-absorbable, synthetic,
              and natural options. Our selection includes:
            </p>
            <ul className="list-disc ml-5">
              <li>Monofilament and multifilament medical sutures.</li>
              <li>
                Offering the best fit for any surgical dressing—trust Nuvo
                Medsurg for the finest sutures.
              </li>
              <li>Ensuring accuracy and reliability in every procedure.</li>
            </ul>
            <p>
              In addition to sutures, Nuvo Medsurg excels as a surgical dressing
              manufacturer, providing comprehensive solutions for wound care. We
              offer various medical-surgical instruments to ensure complete care
              during surgical procedures.
            </p>
          </div>
        </div>

        {/* Image */}
        <LazyLoadImage
          className="w-10/12 mx-auto h-auto max-h-96 object-contain rounded-lg"
          alt="Surgical Dressings and Sutures"
          src="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051167/f8rnmfyyuw3uvdqh1ptl.webp"
          loading="lazy"
          effect="blur"
        />
      </motion.div>

      {/* Call-to-Action Button */}
      <motion.button
        // Fade in up for the button
        variants={fadeInUp}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        onClick={() => navigate("/categories")}
        className="cursor-pointer bg-black px-8 py-3 rounded-lg w-fit mx-auto text-white hover:bg-gray-800 transition-colors duration-200"
      >
        <p className="text-lg text-center">View All Products</p>
      </motion.button>
    </div>
  );
};
