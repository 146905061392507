import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isAuth } from "../helpers/authHelper";
import { useNuvoContext } from "../context/NuvoContext";

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();

  const { isAuthenticated } = useNuvoContext();
  if (!isAuthenticated) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
