import React from "react";

interface ShimmerProps {
  w: string;
  h: number;
  radius?: string;
}

const NuvoShimmer: React.FC<ShimmerProps> = ({ w, h, radius = "4px" }) => {
  return (
    <div
      style={{ width: w, height: `${h}px`, borderRadius: radius }}
      className="bg-gray-300 animate-pulse"
    ></div>
  );
};

export default NuvoShimmer;
