// src/components/FAQSection.tsx

import React from "react";
import FAQs from "./Faq";
import { faqs } from "./Faq.Data";
import JSONLD from "../../../utils/seo-schema/seoSchema";
import { FAQPageSchema } from "../../../utils/seo-schema/seoSchemaData";

const FAQSection: React.FC = () => {
  return (
    <><JSONLD data={FAQPageSchema} /><section className="py-12 bg-gray-50">
      <FAQs faqs={faqs} />
    </section></>
  );
};

export default FAQSection;
