import { LazyLoadImage } from "react-lazy-load-image-component";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  imageSrc?: string;
  imageAlt?: string;
  imagePosition?: "left" | "right";
  imageClassName?: string;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  imageSrc,
  imageAlt,
  imagePosition = "left",
  imageClassName = "",
}) => {
  const hasImage = !!imageSrc;
  const imageClass = imageClassName || "max-h-[400px] w-full";
  const imageElement = hasImage && (
    <LazyLoadImage
      src={imageSrc}
      alt={imageAlt || title}
      loading="lazy"
      effect="blur"
      className={imageClass}
    />
  );

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 gap-5 ${
        hasImage ? "items-center" : ""
      } `}
    >
      {imagePosition === "left" && hasImage && imageElement}
      <div className="flex flex-col gap-5">
        {title && (
          <div className="w-fit">
            <h2 className="text-lg md:text-xl lg:text-2xl">{title}</h2>
            <hr className="border border-black" />
          </div>
        )}
        {children}
      </div>
      {imagePosition === "right" && hasImage && imageElement}
    </div>
  );
};

interface ContentListProps {
  items: string[];
  className?: string;
  itemClassName?: string;
}

const ContentList: React.FC<ContentListProps> = ({
  items,
  className = "",
  itemClassName = "",
}) => (
  <ul className={`list-disc ml-5 ${className}`}>
    {items.map((item, index) => (
      <li key={index} className={itemClassName}>
        {item}
      </li>
    ))}
  </ul>
);

export { Section, ContentList };
