import { FaClock, FaMap, FaUser, FaDesktop } from "react-icons/fa";

export interface ExperienceService {
  Icon: React.ComponentType<{ className?: string }>;
  content: string;
  content2: string;
}

export const experienceServices: ExperienceService[] = [
  {
    Icon: FaClock,
    content: "11",
    content2: "Years",
  },
  {
    Icon: FaMap,
    content: "15",
    content2: "Countries",
  },
  {
    Icon: FaUser,
    content: "50",
    content2: "Employees",
  },
  {
    Icon: FaDesktop,
    content: "250",
    content2: "Distributors",
  },
];
