import "./table.css";

interface Column {
  header: string;
  accessor: string;
}

interface ReusableTableProps {
  columns: Column[];
  data: any[];
  showHeader?: boolean;
  tableClass?: string;
}

const NuvoTable: React.FC<ReusableTableProps> = ({
  columns,
  data,
  showHeader = false,
}) => {
  return (
    <div className="table-container">
      <table className="table">
        {showHeader && (
          <thead className="bg-teal-300">
            <tr>
              {columns.map((column) => (
                <th key={column.accessor}>{column.header}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column) => (
                <td key={column.accessor}>{row[column.accessor]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NuvoTable;
