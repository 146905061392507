import axios from "axios";
import baseUrl from "./baseUrl";
import { LoginResponse } from "../types/user.types";
import { getLocalStorage } from "../helpers/authHelper";

export const createPayment = async (
  //   orderId: string,
  amount: number,
  currency: string
) => {
  const user: LoginResponse = getLocalStorage("nuvo_user_cred");

  const { data } = await axios.post(
    `${baseUrl}/payment/order`,
    {
      amount: amount,
      currency: currency,
    },
    {
      headers: {
        Authorization: "Bearer " + user.token,
      },
    }
  );
  return data?.data;
};
