import { h2ClassName } from "../../single-category-css";

export const SectionTwo = () => {
  const suturesCharacteristics = [
    {
      title: "Tensile Strength",
      description:
        "Tensile strength ensures that sutures can withstand stress and tension during and after the surgical procedure, providing reliable support for wound healing without breaking or deteriorating.",
    },
    {
      title: "Biocompatibility",
      description:
        "Biocompatible sutures do not cause adverse reactions in the body. They integrate smoothly with tissues, minimizing the risk of inflammation, infection, or rejection and promoting a safe healing process.",
    },
    {
      title: "Knot Security",
      description:
        "Knot security is crucial for maintaining wound closure. High-quality sutures form secure knots that hold firmly under tension, ensuring the wound edges remain approximated during the healing period.",
    },
    {
      title: "Minimal Tissue Reaction",
      description:
        "Sutures designed for minimal tissue reaction reduce inflammation and scarring. This feature enhances patient comfort and accelerates healing, ensuring better post-surgical outcomes.",
    },
    {
      title: "Flexibility and Elasticity",
      description:
        "Flexible and elastic sutures adapt to the movements of the body. This characteristic is essential for areas subjected to motion, as it prevents tissue damage and promotes effective healing.",
    },
    {
      title: "Sterility",
      description:
        "Sterile sutures are free from pathogens, ensuring a safe surgical environment. Maintaining sterility prevents infections, protects patients, and contributes to successful surgical procedures.",
    },
  ];

  return (
    <div className="flex flex-col gap-10 ">
      <div className="text-justify flex flex-col gap-4 my-5 text-slate-500 text-sm md:text-lg">
        <p className="text-sm md:text-base">
          The main functions of surgical sutures comprise healing the wounds,
          promoting tissue recovery, controlling bleeding (hemostasis), and
          minimizing scars. Surgical sutures are necessary for medical treatment
          efficacy and patient security. Sutures serve a key role in several
          aspects of surgical treatment, influencing both immediate and
          long-term results. The Right surgical sutures are constructed of
          biocompatible materials that do not irritate the body's tissues. It
          helps reduce the possibility of unpleasant reactions and infections,
          which can lead to problems and longer recovery times. Sutures
          typically stay in your body to prevent bacteria from entering the
          wound, resulting in a sterile environment that is suitable for
          healing.
        </p>
        <p>
          They reduce irritation and inflammation, relieving pain and suffering
          during healing. The right sutures mitigate the possibility of
          complications after surgery, resulting in a safer recovery for the
          patient.
        </p>
      </div>
      <div className="flex flex-col gap-5">
        <h2 className={h2ClassName}>Types of Sutures</h2>
        <p className="text-sm md:text-base">
          Sutures are used in surgical operations to close wounds and facilitate
          tissue repair. Different types of sutures are used according to the
          specific requirements of the surgery and the wound's characteristics.
          Here's an overview of the main types of sutures:
        </p>
        <div className="flex flex-col gap-10 md:gap-[150px] md:mt-20 md:pb-20 ">
          <div className="text-justify grid grid-cols-1 md:grid-cols-2 gap-5">
            <img
              className="w-10/12 m-auto"
              src={
                "https://res.cloudinary.com/dzqi3eyia/image/upload/v1728066726/er5cdprfln0ikevst4or.webp"
              }
              alt="surgicalImageType1"
            />
            <div className="flex flex-col gap-5">
              <h3 className="text-md md:text-3xl font-medium">
                Absorbable and Non-Absorbable Sutures
              </h3>
              <p className="text-sm md:text-base text-slate-500">
                <h4 className="text-black font-bold">
                  <strong>Absorbable Sutures :</strong>
                </h4>
                Absorbable sutures are designed to dissolve and be absorbed by
                the body over time. There is no need for insertion, and they are
                best suited for interior tissues that recover fast. Absorbable
                Sutures are typically made of materials like Polyglycolic acid,
                polyglactin, and polydioxanone.
              </p>
              <p className="text-sm md:text-base text-slate-500">
                <h4 className="text-black font-bold">
                  <strong> Non-absorbable Sutures :</strong>
                </h4>
                These types of sutures are intended to stay in the body
                indefinitely or until manually removed. They offer long-term
                support and are employed in tissues requiring longer healing
                time. Non-absorbable sutures are most typically made of nylon,
                polypropylene, or polyester.
              </p>
            </div>
          </div>
          <div className="text-justify grid grid-cols-1 md:grid-cols-2 gap-5">
            <div className="flex flex-col gap-5">
              <h3 className="text-md md:text-3xl font-medium">
                Monofilament and Multifilament Sutures
              </h3>
              <p className="text-sm md:text-base text-slate-500">
                <h4 className="text-black font-bold">
                  <strong>Monofilament Sutures :</strong>
                </h4>
                Composed of a single strand of material, monofilament sutures
                provide a smooth passage through tissues, decreasing tissue
                stress and the risk of infection. They are less likely to
                contain germs and are more straightforward to work with while
                suturing. Nylon and polypropylene are some examples.
              </p>
              <p className="text-sm md:text-base text-slate-500">
                <h4 className="text-black font-bold">
                  <strong>Multifilament Sutures :</strong>
                </h4>
                These are twisted or braided strands that provide extra strength
                and flexibility. While they provide superior knot security and
                manageability, they tend to harbor bacteria more. Silk and
                polyester are standard materials.
              </p>
            </div>
            <img
              className="w-10/12 m-auto"
              src={
                "https://res.cloudinary.com/dzqi3eyia/image/upload/v1728066726/fjjzguhycen201x5qowf.webp"
              }
              alt="surgicalImageType2"
            />
          </div>
          <div className="text-justify grid grid-cols-1 md:grid-cols-2 gap-5">
            <img
              className="w-10/12 m-auto"
              src={
                "https://res.cloudinary.com/dzqi3eyia/image/upload/v1728066725/o3f4jcy4maje3siefaxb.webp"
              }
              alt="surgicalImageType3"
            />
            <div className="flex flex-col gap-5">
              <h3 className="text-md md:text-3xl font-medium">
                Coated and Uncoated Sutures
              </h3>
              <p className="text-sm md:text-base text-slate-500">
                <h4 className="text-black font-bold">
                  <strong>Coated Sutures :</strong>
                </h4>
                Coated sutures feature an extra coating that improves their
                handling qualities and lowers tissue drag. The coating may also
                give antibacterial advantages, lowering the risk of infection.
                Coated sutures are chosen when a smooth passage through tissue
                and low friction are required.
              </p>
              <p className="text-sm md:text-base text-slate-500">
                <h4 className="text-black font-bold">
                  <strong>Uncoated Sutures:</strong>
                </h4>
                These sutures do not have any extra coating layers. While they
                may not glide as easily through tissue, they can give superior
                knot stability and are less prone to slipping. Uncoated sutures
                are appropriate for various surgical applications in which knot
                dependability is critical.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-10">
        <h3 className="text-xl md:text-3xl font-medium">
          Characteristics of Sutures
        </h3>
        <div className="text-justify gap-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {suturesCharacteristics.map((chr) => (
            <div className="bg-blue-100 rounded-xl text-lg p-4 flex flex-col gap-5 justify-center">
              <h4 className="font-bold text-center">{chr.title}</h4>
              <p className="">{chr.description}</p>
            </div>
          ))}
        </div>
      </div>
      <p className="text-justify text-sm md:text-base w-11/12 md:w-8/12 mx-auto text-slate-500">
        Now access the largest selection of surgical sutures online. Enjoy
        top-quality products at the most affordable prices, ensuring excellence
        and value in every purchase.
      </p>
    </div>
  );
};
