// src/components/SingleCategoryView/SingleCategoryView.tsx

import { useEffect, useState } from "react";
import { SingleProductType } from "../../types/product.types";
import { getProductsByCategory } from "../../handlers/productHandler";
import { useNavigate, useParams } from "react-router-dom";
import { BreadCrumbs } from "../shared/BreadCrumbs";
import { unslugify } from "../../utils/UnSlugify";
import { getMinAndMaxPrice } from "../../utils/getMinAndMaxPrice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CategoryPageMetaData } from "./CategoryMeta";
import { CategoryType } from "../../services/meta.service";
import { SurgicalSectionOne, SectionTwo } from "./surgical-sutures";
import { SterileOne, SterilTwo } from "./sterile-dressings";
import {
  DisposableSectionOne,
  DisposableSectionTwo,
} from "./surgical-disposable";
import { SkinStaplerSectionOne, SkinStaplerSectionTwo } from "./skin-stapler";
import { BandageSectionOne } from "./bandages";

import { AlwaysScrollToTop } from "../../utils/scrollToTop";
import { GlovesSectionOne, WipesSectionOne } from "./disinfectant-wipes.tsx";
import NuvoShimmer from "../new-ui/Homepage/featured-section/nuvo-shimmer";
import { NuvoContainer } from "../conatiner/NuvoContainer";

export const SingleCategoryView = () => {
  const [productsData, setProductsData] = useState<SingleProductType[] | null>(
    null
  );
  const [isLoading, setIsloading] = useState<boolean>(false);

  const { categoryName } = useParams<{ categoryName: string }>();

  const navigate = useNavigate();

  const unslugifiedCategoryName = categoryName && unslugify(categoryName);

  const getAllProducts = async (categoryName: string) => {
    setIsloading(true);
    try {
      const data = await getProductsByCategory(categoryName);
      setProductsData(data);
      setIsloading(false);
    } catch (error) {
      console.log(error);
      setIsloading(false);
    }
  };

  useEffect(() => {
    AlwaysScrollToTop();
  }, []);

  useEffect(() => {
    if (categoryName) {
      getAllProducts(categoryName);
    }
  }, [categoryName]);

  return (
    <NuvoContainer>
      {categoryName && <CategoryPageMetaData categoryName={categoryName} />}
      <div className="w-full max-w-[10/12] mx-auto px-4">
        <div className="w-full py-5">
          <BreadCrumbs
            breadcrumbs={[
              {
                label: "Home",
                link: "/",
              },
              {
                label: "Category",
                link: "/categories",
              },
              {
                label: unslugifiedCategoryName as string,
              },
            ]}
          />
        </div>

        <p className="text-3xl md:text-4xl font-bold pb-10 text-center">
          {unslugifiedCategoryName} Products
        </p>

        {isLoading ? (
          <div className="w-full overflow-x-auto scrollbar-hide">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {Array(6)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className="bg-nuvo-card shadow-md w-full min-h-[480px] p-4 flex flex-col rounded-lg animate-pulse"
                  >
                    <NuvoShimmer w="100%" h={200} radius="8px" />
                    <div className="mt-4 h-6 bg-gray-300 rounded w-3/4"></div>
                    <div className="mt-2 h-6 bg-gray-300 rounded w-1/2"></div>
                    <div className="mt-4 h-10 bg-gray-300 rounded w-full"></div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <>
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 pb-10">
              {productsData &&
                productsData.map((product) => {
                  const { maxPrice, minPrice } = getMinAndMaxPrice(
                    product.variants
                  );

                  return (
                    <div
                      key={product._id}
                      className="cursor-pointer shadow-md flex flex-col rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                      onClick={() =>
                        navigate(`/categories/${categoryName}/${product.slug}`)
                      }
                      role="button"
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          navigate(
                            `/categories/${categoryName}/${product.slug}`
                          );
                        }
                      }}
                      aria-label={`View details for ${product.name}`}
                    >
                      <div className="h-60 md:h-72 w-full flex justify-center items-center bg-gray-100">
                        <LazyLoadImage
                          className={`${
                            product.name ===
                            "NON STERILE ABDOMINAL SPONGE T-13 & T-17, X-RAY"
                              ? "w-1/2"
                              : "w-8/12"
                          } mx-auto h-auto object-cover`}
                          src={product.images[0]}
                          alt={product.name}
                          effect="blur"
                          loading="lazy"
                        />
                      </div>

                      {/* Product Details */}
                      <div className="flex flex-col justify-between p-4 flex-grow">
                        <div className="mb-4">
                          <p className="text-lg md:text-xl font-semibold text-gray-800 truncate">
                            {product.name.length > 30
                              ? product.name.slice(0, 30) + "..."
                              : product.name}
                          </p>
                          <div className="flex items-center mt-2">
                            <span className="text-lg font-medium text-gray-700">
                              ₹ {minPrice}
                            </span>
                            <span className="mx-2 text-gray-500">-</span>
                            <span className="text-lg font-medium text-gray-700">
                              ₹ {maxPrice}
                            </span>
                          </div>
                        </div>

                        <div
                          className="mt-auto bg-black text-white text-center py-2 rounded-md hover:bg-gray-800 transition-colors duration-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/categories/${categoryName}/${product.slug}`
                            );
                          }}
                          role="button"
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              navigate(
                                `/categories/${categoryName}/${product.slug}`
                              );
                            }
                          }}
                          aria-label={`Purchase ${product.name} now`}
                        >
                          <p className="font-medium">Purchase Now</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        <div className="mt-10">
          {categoryName === "surgical-sutures" && <SurgicalSectionOne />}
          {categoryName === "sterile-dressings" && <SterileOne />}
          {categoryName === "surgical-disposable" && <DisposableSectionOne />}
          {categoryName === "skin-stapler" && <SkinStaplerSectionOne />}
          {categoryName === "surgical-sutures" && <SectionTwo />}
          {categoryName === "sterile-dressings" && <SterilTwo />}
          {categoryName === "surgical-disposable" && <DisposableSectionTwo />}
          {categoryName === "skin-stapler" && <SkinStaplerSectionTwo />}
          {categoryName === "bandages" && <BandageSectionOne />}
          {categoryName === "disinfectant-wipes" && <WipesSectionOne />}
          {categoryName === "gloves" && <GlovesSectionOne />}
        </div>
      </div>
    </NuvoContainer>
  );
};

export default SingleCategoryView;
