import emptyDashboard from "../../../assets/empty-state/empty-state.png";
import emptyOrder from "../../../assets/empty-state/orders.png";

type EmptyStateType = "DASHBOARD" | "ORDERS";
export const EmptyState = ({
  type = "DASHBOARD",
  title,
  description,
}: {
  type?: EmptyStateType;
  title: string;
  description?: string;
}) => {
  return (
    <div className="flex flex-col items-center justify-center h-96">
      {type === "DASHBOARD" ? (
        <img className="w-20 h-20" src={emptyDashboard} alt="Empty Dashboard" />
      ) : (
        <img className="w-20 h-20" src={emptyOrder} alt="Empty Orders" />
      )}
      <p className="text-lg text-gray-500 font-medium mt-4">{title}</p>
      <p className="text-sm text-gray-400 mt-2 text-center">{description}</p>
    </div>
  );
};
