import axios from "axios";
import baseUrl from "./baseUrl";
import { LoginResponse, UserAddress } from "../types/user.types";

export type CreateAddressResponseType = {
  user: string;
  streetName: string;
  doorNo: string;
  pincode: string;
  city: string;
  state: string;
  landMark: string;
  type: "Personal" | "Office";
  _id: string;
};
export const createAddressHandler = async (
  streetName: string,
  doorNo: string,
  city: string,
  state: string,
  pinCode: string,
  bearerToken: string,
  landMark: string | null,
  type: string
) => {
  const { data } = await axios.post(
    `${baseUrl}/address/create`,
    {
      streetName: streetName,
      doorNo: doorNo,
      city: city,
      state: state,
      pinCode: pinCode,
      landMark: landMark ? landMark : null,
      type: type,
    },
    {
      headers: {
        Authorization: "Bearer " + bearerToken,
      },
    }
  );
  return data?.data as CreateAddressResponseType;
};

export const editAddressHandler = async (
  streetName: string,
  doorNo: string,
  city: string,
  state: string,
  pinCode: string,
  landMark: string | null,
  type: string,
  addressId: string,
  bearerToken: string
) => {
  const { data } = await axios.post(
    `${baseUrl}/address/edit`,
    {
      streetName: streetName,
      doorNo: doorNo,
      city: city,
      state: state,
      pinCode: pinCode,
      landMark: landMark ? landMark : null,
      type: type,
      addressId: addressId,
    },
    {
      headers: {
        Authorization: "Bearer " + bearerToken,
      },
    }
  );
  return data as LoginResponse;
};

export const deleteAddressHandler = async (
  addressId: string,
  bearerToken: string
) => {
  const { data } = await axios.post(
    `${baseUrl}/address/delete`,
    {
      addressId: addressId,
    },
    {
      headers: {
        Authorization: "Bearer " + bearerToken,
      },
    }
  );
  return data;
};
export const getAddressHandler = async (bearerToken: string) => {
  const response = await axios.get(`${baseUrl}/address/get`, {
    headers: {
      Authorization: "Bearer " + bearerToken,
    },
  });

  return response?.data?.data as UserAddress[];
};
