import React, { Suspense, lazy } from "react";
import { CategoryDataType } from "../../types/category.types";
import Shimmer from "../Shimmer/Shimmer";

// Lazy load the LazyLoadImage component
const LazyLoadImage = lazy(() =>
  import("react-lazy-load-image-component").then((module) => ({
    default: module.LazyLoadImage,
  }))
);

export const CategoriesNew = ({ data }: { data: CategoryDataType }) => {
  // Filter and sort the data
  const filteredData = data
    .filter((d) => ["SURGICAL SUTURES", "SKIN STAPLER"].includes(d.name))
    .sort((a, b) => {
      const order = ["SURGICAL SUTURES", "SKIN STAPLER"];
      return order.indexOf(a.name) - order.indexOf(b.name);
    });

  // Define stapler applications
  const staplerApplications = [
    "For Skin closure in Operating Room",
    "Emergency Department",
    "Labor and Delivery",
    "Outpatient Surgery",
    "Clinics and Physician’s Office",
  ];

  return (
    <div className="w-11/12 mx-auto flex flex-col gap-5 mt-2">
      <div className="flex flex-col gap-5">
        {filteredData.map((fd) => (
          <div
            key={fd.name}
            className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10 items-center"
          >
            {/* Image Section */}
            <Suspense
              fallback={
                <div className="cursor-pointer bg-nuvo-card shadow-md min-w-full md:min-w-[450px] flex-1 flex p-4 md:p-6 flex-col items-center rounded-lg">
                  <Shimmer w="100%" h={200} radius="4px 4px 0 0" />
                </div>
              }
            >
              <LazyLoadImage
                src={fd.image}
                alt={fd.name}
                className="w-8/12 mx-auto h-auto object-contain"
                effect="blur"
                loading="lazy"
              />
            </Suspense>

            {/* Content Section */}
            {fd.name === "SURGICAL SUTURES" && (
              <div className="flex flex-col gap-4">
                <p className="text-base md:text-lg font-semibold">
                  Nuvo Sutures is the brand name of surgical sutures
                  manufactured by Nuvo Medsurg.
                </p>
                <div className="text-justify text-gray-600 text-sm md:text-base flex flex-col gap-3">
                  <p>
                    Surgical sutures are vital healthcare devices used for
                    closing wounds and incisions, promoting healing. These
                    sutures come in a variety of materials and are designed to
                    meet the stringent requirements of surgical procedures,
                    ensuring healthy and rapid wound recovery.
                  </p>
                  <p>
                    Known for their versatility, biocompatibility, and strength,
                    sutures are engineered to prevent adverse reactions,
                    accommodate the body's natural movements, and maintain
                    tissue integrity under stress.
                  </p>
                  <p>
                    Surgical sutures play a crucial role in healthcare by
                    ensuring the success of operations and patient recovery.
                    Their main functions include wound healing, tissue recovery,
                    bleeding control (hemostasis), and scar minimization,
                    thereby enhancing medical treatment efficacy and patient
                    safety.
                  </p>
                </div>
              </div>
            )}

            {fd.name === "SKIN STAPLER" && (
              <div className="flex flex-col gap-4">
                <p className="text-base md:text-lg font-semibold">
                  NUVOFIX Staple Remover is specially designed to efficiently
                  remove staples after skin closure using our disposable skin
                  stapler.
                </p>
                <div className="text-justify text-gray-600 text-sm md:text-base flex flex-col gap-3">
                  <p>
                    NUVOFIX™ Skin Stapler, also known as a wound stapler or
                    surgical stapler, is a medical tool used for various skin
                    closures. The staples first penetrate the skin and are then
                    formed, holding the tissue together securely.
                  </p>
                  <ul className="ml-5 list-disc">
                    {staplerApplications.map((application, index) => (
                      <li key={index}>{application}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
