import axios from "axios";
import { MyCartType } from "../types/cart.types";
import baseUrl from "./baseUrl";
import { ProcessedCartItemType } from "../components/cart/panels/RightPanel";

export type ProductAddToCartType = {
  quantity: number;
  id: string;
  size: string;
  code: string;
};

export type AddToCartResponse = {
  status: string;
  message: string;
  cartId?: string;
};

export const addToCart = async (
  product: ProcessedCartItemType[],
  bearerToken: string
) => {
  const { data } = await axios.post(
    `${baseUrl}/cart/create`,
    { products: product },
    {
      headers: {
        Authorization: "Bearer " + bearerToken,
      },
    }
  );
  return data as AddToCartResponse;
};

export const getMyCart = async (bearerToken: string) => {
  const { data } = await axios.get(`${baseUrl}/cart/myCart`, {
    headers: {
      Authorization: "Bearer " + bearerToken,
    },
  });

  return data?.data[0] as MyCartType;
};

export const deleteCartItem = async (
  bearerToken: string,
  cartItemId: string
) => {
  const { data } = await axios.delete(`${baseUrl}/cart/myCart/item`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    data: {
      cartItemId: cartItemId,
    },
  });

  return data;
};

export const deleteCart = async (bearerToken: string, cartId: string) => {
  const { data } = await axios.delete(`${baseUrl}/cart/myCart`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
    },
    data: {
      cartId: cartId,
    },
  });

  return data;
};
