import React, { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useInView, motion } from "framer-motion";
import { containerVariants } from "../new-ui/Footer";

export const WhyUs = () => {
  const data = [
    {
      title: "Unmatched Quality:",
      description:
        "As a leading surgical suture manufacturer in India, Nuvo Medsurg offers top-notch quality using premium materials and advanced techniques. Our products satisfy strict international standards, ensuring dependability and accuracy in every surgical procedure.",
    },
    {
      title: "Innovative Solutions:",
      description:
        "As leading surgical suture manufacturers, we focus on innovation to address the changing needs of medical professionals. Our innovative absorbable and non-absorbable sutures have been designed for more remarkable performance and enhanced surgical results.",
    },
    {
      title: "Dedicated Support:",
      description:
        "Nuvo Medsurg has dedicated itself to delivering excellent customer service to our clients. We provide experienced guidance and timely help, guaranteeing that healthcare professionals and businesses can rely on us for smooth integration and the best product performance.",
    },
  ];

  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <section className="w-11/12 mx-auto py-5">
      {/* Section Title */}
      <h2 className="text-2xl font-semibold text-center mb-8 md:mb-12">
        Why Choose Nuvo Medsurg?
      </h2>

      {/* Content Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-16 items-start">
        {/* Text Section */}
        <div className="flex flex-col gap-6">
          {data.map((item) => (
            <div key={item.title}>
              <h3 className="text-lg font-semibold text-black mb-2">
                {item.title}
              </h3>
              <p className="text-sm md:text-base text-slate-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>

        {/* Images Section */}
        <motion.div
          className="grid grid-cols-2 gap-4 md:gap-6"
          ref={ref}
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
        >
          <LazyLoadImage
            src="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051839/gqn2qno1oowzhvsu5ver.webp"
            alt="Nuvo Medsurg Quality Assurance"
            className="w-8/12 h-auto object-cover"
            effect="blur"
            loading="lazy"
          />

          <LazyLoadImage
            src="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051838/shuqa9z0kcpwyhmahhi0.webp"
            alt="Innovative Surgical Sutures"
            className="w-8/12 h-auto object-cover"
            effect="blur"
            loading="lazy"
          />

          <LazyLoadImage
            src="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051838/ve6h6mnjlsrlxanexlmb.webp"
            alt="Dedicated Customer Support"
            className="w-8/12 h-auto object-cover"
            effect="blur"
            loading="lazy"
          />

          <LazyLoadImage
            src="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728051838/n8zeu3cftjybop5k1aio.webp"
            alt="Advanced Surgical Techniques"
            className="w-8/12 h-auto object-cover"
            effect="blur"
            loading="lazy"
          />
        </motion.div>
      </div>
    </section>
  );
};
