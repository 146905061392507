import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import image from "../assets/hero-images/heroImage6.jpeg";
import image2 from "../assets/hero-images/heroImage7.jpeg";
import image3 from "../assets/hero-images/heroImage4.jpg";

type Slide = {
  image: string;
  heading: string;
  paragraph: string;
  button1Text?: string;
  button1Route?: string;
  button2Text?: string;
  button2Route?: string;
};

const slides: Slide[] = [
  {
    image,
    heading: "Delivering Precision and Trust in Every Stitch.",
    paragraph:
      "Manufacturing high-quality sutures that ensure safety and reliability in critical procedures.",
    button1Text: "Explore Our Range",
    button1Route: "/categories",
    button2Text: "Talk to Our Experts",
    button2Route: "/contact-us",
  },
  {
    image: image2,
    heading: "Commitment to Quality, Trusted by Healthcare Professionals.",
    paragraph:
      "We prioritize safety and excellence to deliver sutures that meet the toughest surgical challenges.",
  },
  {
    image: image3,
    heading: "Your Trusted Partner in Surgical Excellence.",
    paragraph:
      "With decades of expertise, we provide sutures that doctors trust and patients rely on.",
    button1Text: "Start Your Order",
    button1Route: "/categories",
    button2Text: "Contact Our Team",
    button2Route: "/contact-us",
  },
];

export const AutoCarousel: React.FC = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const {
    image,
    heading,
    paragraph,
    button1Text,
    button1Route,
    button2Text,
    button2Route,
  } = slides[currentSlide];

  return (
    <div className="relative w-full mt-10 mx-auto transition-all duration-500">
      {/* Slide Image */}
      <img
        className="w-11/12 h-[40vh] lg:h-[60vh] mx-auto object-cover rounded-lg"
        src={image}
        alt="HomeBanner"
      />

      {/* Semi-transparent overlay for text readability */}
      <div className="absolute w-11/12 mx-auto inset-0 bg-black/40 rounded-lg"></div>

      {/* Text and buttons */}
      <div className="absolute top-0 left-10 xl:left-20 w-full h-full flex flex-col items-start justify-center p-2 lg:p-8 xl:p-20">
        <div>
          <h2 className="text-white text-xs sm:text-md md:text-xl xl:text-4xl max-w-[220px] sm:max-w-l md:max-w-xl xl:max-w-3xl font-bold">
            {heading}
          </h2>
          <p className="text-white text-[8px] sm:text-xs md:text-sm xl:text-xl max-w-[200px] md:max-w-xl mt-2 lg:mt-6">
            {paragraph}
          </p>
        </div>
        {/* Optional buttons if specified in the slide object */}
        {(button1Text || button2Text) && (
          <div className="mt-4 lg:mt-10 xl:mt-20">
            {button1Text && button1Route && (
              <button
                className="bg-white text-[8px] sm:text-xs md:text-lg text-black px-2 md:px-6 py-1 md:py-2 rounded-lg"
                onClick={() => navigate(button1Route)}
              >
                {button1Text}
              </button>
            )}
            {button2Text && button2Route && (
              <button
                className="bg-transparent text-[8px] sm:text-xs md:text-lg text-white px-2 md:px-6 py-1 md:py-2 ml-4 rounded-lg border border-white"
                onClick={() => navigate(button2Route)}
              >
                {button2Text}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
