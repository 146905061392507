import { useState, useEffect } from "react";

export interface Product {
  name: string;
  category: {
    name: string;
    slug: string;
  };
  slug: string;
  image: string;
}

export const useProductSearch = (data: Product[], query: string) => {
  const [filteredData, setFilteredData] = useState<Product[]>([]);

  useEffect(() => {
    if (query.trim() === "") {
      setFilteredData([]);
    } else {
      const filtered = data.filter((product) =>
        product.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [data, query]);

  return filteredData;
};
