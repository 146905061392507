import React, { useRef, useState, useEffect } from "react";

import { experienceServices } from "../../services/experience.services";
import { ContactCta } from "./ContactCta";
import CountUp from "react-countup";

const useScrollTrigger = (ref: React.RefObject<HTMLElement>, offset = 0) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const scrollObserver = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin: `${offset}px` }
    );

    if (ref.current) {
      scrollObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        scrollObserver.unobserve(ref.current);
      }
    };
  }, [ref, offset]);

  return isVisible;
};

export default function Experience() {
  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <ContactCta />
      <div className="w-10/12 mx-auto bg-gray-100 rounded-md">
        <div
          ref={contentRef}
          className="z-10"
          style={{
            willChange: "transform",
          }}
        >
          <div className="container mx-auto p-3 lg:px-4 lg:py-8 h-full flex flex-col justify-center items-center">
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[4rem] md:gap-[6rem] lg:gap-[10rem] text-white">
              {experienceServices.map((el, index) => (
                <CounterSection key={index} el={el} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const CounterSection = ({ el }: { el: any }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const isVisible = useScrollTrigger(sectionRef, 100);

  const Icon = el.Icon;

  if (!Icon) {
    return null;
  }

  return (
    <div ref={sectionRef} className="flex flex-col gap-2 md:gap-4 lg:gap-6">
      <Icon className="w-8 h-8 md:w-12 md:h-12 lg:w-[100px] lg:h-[100px] mx-auto text-gray-500" />
      <div className="flex flex-col gap-2 font-extralight text-gray-500">
        <p className="md:text-lg lg:text-3xl mx-auto">
          {isVisible && (
            <CountUp end={parseInt(el.content)} duration={4} suffix="+" />
          )}
        </p>
        <p className="text-sm md:text-base lg:text-xl mx-auto">{el.content2}</p>
      </div>
    </div>
  );
};
