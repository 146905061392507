// src/components/AuthDrawer.tsx

import { useState, useEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import { NuvoDrawer } from "../nuvo-drawer/NuvoDrawer";
import useWindowWidth from "../../hooks/useWindowWidth";
import LoginForm from "./Login";
import SignUpForm from "./SignUp";

type AuthDrawerProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AuthDrawer = ({ isOpen, setIsOpen }: AuthDrawerProps) => {
  const { isLg } = useWindowWidth();
  const [isLogin, setIsLogin] = useState(true); // true for Login, false for SignUp

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      setIsLogin(true); // Reset to Login when drawer is closed
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  const toggleForm = () => {
    setIsLogin((prev) => !prev);
  };

  return (
    <NuvoDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      direction={isLg ? "right" : "bottom"}
      title={isLogin ? "Log In" : "Sign Up"}
    >
      {isLogin ? (
        <LoginForm onSwitch={toggleForm} setIsOpen={setIsOpen} />
      ) : (
        <SignUpForm onSwitch={toggleForm} setIsOpen={setIsOpen} />
      )}
    </NuvoDrawer>
  );
};

export default AuthDrawer;
