// src/components/ProductDetailsComponent.tsx

import {
  ProductDescriptionType,
  SingleProductType,
} from "../../types/product.types";

import NuvoTable from "../shared/table/NuvoTable";

export const ProductDetailsComponent = ({
  productData,
}: {
  productData: SingleProductType;
}) => {
  const metaData = productData.metaData;

  // Define columns for tensile strength table
  const tensileColumns = metaData?.tensileStrength?.every((ts) => ts.subcat)
    ? [
        { header: "Post Implantation", accessor: "postIm" },
        { header: "Tensile Strength", accessor: "strength" },
        { header: "Sub Category", accessor: "subCat" },
      ]
    : [
        { header: "Post Implantation", accessor: "postIm" },
        { header: "Tensile Strength", accessor: "strength" },
      ];

  // Format tensile strength data
  const formattedTensileData =
    metaData.tensileStrength?.map((item) => ({
      postIm: `${item.Post_Implantation} days`,
      strength: item.strength ? `${item.strength} %` : "N/A",
      subCat:
        item.subcat?.length > 0
          ? item.subcat.map((sub) => `${sub.name}: ${sub.value}`).join(", ")
          : "N/A",
    })) || [];

  // Define columns for product description table
  const productDescColumns = [
    { header: "Key", accessor: "key" },
    { header: "Value", accessor: "value" },
  ];

  // Flatten product description data
  const flattenedProductDescData =
    metaData.product_Desc?.map((descItem) => {
      const key = Object.keys(descItem)[0];
      const value = descItem[key as keyof ProductDescriptionType];
      return { key, value };
    }) || [];

  // Define generic table columns
  const columns = [
    { header: "", accessor: "id" },
    { header: "", accessor: "value" },
  ];

  // Prepare generic table data
  const data = Object.entries(productData.metaData).map(([key, value]) => ({
    id: key,
    value: typeof value === "object" ? JSON.stringify(value) : value,
  }));

  const isNuvomono = productData.name === "Nuvomono";

  return (
    <div className="flex flex-col gap-8">
      {/* Product Description Heading */}
      <h2 className="text-2xl md:text-3xl font-bold text-gray-800">
        Product Description:
      </h2>

      {/* Conditional Rendering for SKIN STAPLER Category */}
      {productData.category.name === "SKIN STAPLER" && (
        <div className="flex flex-col gap-10">
          {/* Benefits Section */}
          {metaData.benefits && (
            <div className="flex flex-col md:flex-row gap-10 items-center">
              {/* Benefits List */}
              <div className="md:w-1/2">
                <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mb-4">
                  Benefits:
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  {metaData.benefits.map((benefit, index) => (
                    <li key={index} className="text-base md:text-lg">
                      {benefit}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Product Image */}
              {productData.images.length > 0 && (
                <div className="md:w-1/2 flex justify-center items-center">
                  <img
                    alt={productData.name}
                    src={productData.images[0]}
                    className="w-8/12 mx-auto h-auto object-contain"
                    loading="lazy"
                  />
                </div>
              )}
            </div>
          )}

          {/* Applications Section */}
          {metaData.applications && (
            <div className="flex items-center flex-col md:flex-row gap-10">
              {/* Product Image */}
              {productData.images.length > 1 && (
                <div className="md:w-1/2 flex justify-center items-center">
                  <img
                    alt={productData.name}
                    src={productData.images[1]}
                    className="w-10/12 mx-auto h-auto object-contain"
                    loading="lazy"
                  />
                </div>
              )}

              {/* Applications List */}
              <div className="md:w-1/2">
                <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mb-4">
                  Applications:
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  {metaData.applications.map((application, index) => (
                    <li key={index} className="text-base md:text-lg">
                      {application}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}

          {/* Features and Advantages Section */}
          <div className="flex flex-col md:flex-row gap-10">
            {/* Features List */}
            {metaData.features && (
              <div className="md:w-1/2">
                <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mb-4">
                  Features:
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  {metaData.features.map((feature, index) => (
                    <li key={index} className="text-base md:text-lg">
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* Advantages List */}
            {metaData.advantages && (
              <div className="md:w-1/2">
                <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mb-4">
                  Advantages:
                </h3>
                <ul className="list-disc list-inside space-y-2 text-gray-600">
                  {Array.isArray(metaData.advantages) ? (
                    metaData.advantages.map((advantage, index) => (
                      <li key={index} className="text-base md:text-lg">
                        {advantage}
                      </li>
                    ))
                  ) : (
                    <li className="text-base md:text-lg">
                      {metaData.advantages}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Conditional Rendering for SURGICAL SUTURES Category */}
      {productData.category.name === "SURGICAL SUTURES" && (
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Product Description Table */}
          {metaData.product_Desc && (
            <div
              className={`${isNuvomono ? "lg:col-span-12" : "lg:col-span-8"}`}
            >
              <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mb-4">
                Product Specifications:
              </h3>
              <NuvoTable
                columns={productDescColumns}
                data={flattenedProductDescData}
                // tableClass="w-full table-auto"
                // headerClass="bg-gray-200 text-left px-4 py-2"
                // cellClass="border px-4 py-2"
              />
            </div>
          )}

          {/* Tensile Strength Table */}
          {metaData.tensileStrength && !isNuvomono && (
            <div className="lg:col-span-4">
              <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mb-4">
                Tensile Strength:
              </h3>
              <NuvoTable
                showHeader={true}
                columns={tensileColumns}
                data={formattedTensileData}
                // tableClass="w-full table-auto"
                // headerClass="bg-gray-200 text-left px-4 py-2"
                // cellClass="border px-4 py-2"
              />
            </div>
          )}
        </div>
      )}

      {/* Conditional Rendering for Other Categories */}
      {productData.category.name !== "SURGICAL SUTURES" &&
        productData.category.name !== "SKIN STAPLER" && (
          <div className="w-full">
            {/* <h3 className="text-xl md:text-2xl font-semibold text-gray-700 mb-4">
              Product Metadata:
            </h3> */}
            <NuvoTable
              columns={columns}
              data={data}
              // tableClass="w-full table-auto"
              // headerClass="bg-gray-200 text-left px-4 py-2"
              // cellClass="border px-4 py-2"
            />
          </div>
        )}
    </div>
  );
};

export default ProductDetailsComponent;
