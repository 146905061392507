// src/shared/core/textInput.tsx

import React, { ChangeEvent } from "react";
import Label from "./label";

interface TextInputProps {
  id: string;
  name: string; // Added name prop
  label: string;
  type?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  name, // Destructure name
  label,
  type = "text",
  value,
  onChange,
  placeholder,
  disabled = false,
  className,
}) => {
  return (
    <div className={`mb-4 flex-1 ${className}`}>
      <Label htmlFor={id}>{label}</Label>
      <input
        id={id}
        name={name} // Assign name to input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        className={`mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm ${
          disabled ? "bg-gray-200 cursor-not-allowed" : ""
        }`}
      />
    </div>
  );
};

export default TextInput;
