export interface Testimonial {
  id: number;
  description: string;
  author: string;
  avatar?: string;
}

export const testimonials: Testimonial[] = [
  {
    id: 1,
    description:
      "100 % quality product...Nuvo medsurg Pvt Ltd Is good company...",
    author: "Gaurav Goel",
    avatar: "https://randomuser.me/api/portraits/men/32.jpg",
  },
  {
    id: 2,
    description: "100% high quality products surgical sutures excellent.",
    author: "Anand Mani",
    avatar: "https://randomuser.me/api/portraits/men/44.jpg",
  },
  {
    id: 3,
    description:
      "100% High-Quality Products! The surgical sutures are excellent. Very reliable and durable. Highly recommended!",
    author: "Jyoti Phogat",
    avatar: "https://randomuser.me/api/portraits/women/65.jpg",
  },
  {
    id: 4,
    description:
      "Outstanding quality surgical sutures.100% high-quality products and excellent service.Very satisfied with my purchase.",
    author: "Suryakant Tiwari",
    avatar: "https://randomuser.me/api/portraits/men/65.jpg",
  },
  {
    id: 5,
    description:
      "The sutures are very good quality. Excellent products.Will buy again.",
    author: "Vivek Dhalwal",
    avatar: "https://randomuser.me/api/portraits/men/44.jpg",
  },
  {
    id: 6,
    description:
      "Very impressed with the quality of the surgical sutures.Excellent products. Highly recommend.",
    author: "Rajesh Kumar",
    avatar: "https://randomuser.me/api/portraits/men/44.jpg",
  },
];
