import toast from "react-hot-toast";

import { SingleCartItemType } from "../../../types/cart.types";

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NuvoModal } from "../../shared/modal/Modal";
import { WarningIcon } from "../../../assets/icons/WarningIcon";

import { ProductCode } from "../../../types/product.types";
import { numberToINR } from "../../../utils/numberToInr";
import { useNavigate } from "react-router-dom";

import { getLocalStorage, isAuth } from "../../../helpers/authHelper";

import { useCart } from "../../../context/NuvoCartContext";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { CartInitialValuesType } from "..";
import { AddAddressView } from "../../new-ui/cart/address/add-view";
import { FaTrash } from "react-icons/fa";
import { SelectAddressView } from "./SelectAddressView";

export const CartLeftPanel = ({
  setData,
  setRefreshCart,
  setIsLoading,
}: {
  setData: Dispatch<SetStateAction<SingleCartItemType[]>>;
  setRefreshCart: Dispatch<SetStateAction<Boolean>>;
  setIsLoading: Dispatch<SetStateAction<Boolean>>;
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [showAddAddress, setShowAddAddress] = useState<Boolean>(false);
  const [isCartUpdated, setIsCartUpdated] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(false);

  const onOpenModal = () => setOpenDeleteModal(true);
  const onCloseModal = () => setOpenDeleteModal(false);

  const { values, initialValues } = useFormikContext<CartInitialValuesType>();

  const { updateCartItemsCount } = useCart();

  const navigate = useNavigate();

  const checkAuth = async () => {
    const isAuthenticated = await isAuth();
    setIsAuthenticated(isAuthenticated);
  };
  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <>
      {values?.products.length > 0 && (
        <div>
          {isCartUpdated ||
            (JSON.stringify(initialValues?.products) !==
              JSON.stringify(values?.products) && (
              <div
                onClick={() => {
                  setIsLoading(true);
                  localStorage.removeItem("nuvo_cart");
                  localStorage.setItem(
                    "nuvo_cart",
                    JSON.stringify(values.products)
                  );
                  setData(values.products);
                  setIsLoading(false);
                  setIsCartUpdated(true);
                  toast.success("Cart Item Updated Successfully");
                }}
                className="px-10 py-2 cursor-pointer text-white my-4 w-fit bg-black text-md font-semibold rounded-[8px]"
              >
                Update cart
              </div>
            ))}

          <div className="flex flex-col-reverse md:flex-col gap-2">
            <div className="bg-white shadow-md rounded-md w-full md:min-w-[600px]">
              <div className="py-3 px-5 flex flex-row justify-between rounded-t-[8px]">
                <p className="text-sm font-normal text-black md:text-lg cursor-pointer">
                  Shopping Cart
                </p>
                <p
                  className="font-normal text-sm md:text-lg cursor-pointer text-danger underline"
                  onClick={() => {
                    onOpenModal();
                  }}
                >
                  Clear your cart
                </p>
                {openDeleteModal && (
                  <NuvoModal isOpen={openDeleteModal} onClose={onCloseModal}>
                    <div className="flex flex-col gap-5 p-5">
                      <div className="flex flex-row gap-4 items-center">
                        <WarningIcon />
                        <p className="text-title-6 font-medium">
                          This Action May Not Be Reversible
                        </p>
                      </div>
                      <div className="flex flex-row gap-4 w-full justify-end">
                        <button
                          className="bg-danger text-white px-4 py-2 rounded w-1/2"
                          onClick={() => {
                            try {
                              localStorage.removeItem("nuvo_cart");
                              toast.success("Cart Deleted Successfully");
                              setData([]);
                              updateCartItemsCount();
                              navigate("/categories");
                            } catch (e) {
                              toast.error("something went wrong");
                            }
                          }}
                        >
                          Proceed
                        </button>
                        <button
                          className="bg-white text-danger w-1/2 px-4 py-2 rounded border border-danger"
                          onClick={onCloseModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </NuvoModal>
                )}
              </div>
              <hr className="border-1 w-11/12 mx-auto border-gray-100"></hr>
              <div className="flex flex-col gap-3 p-6 text-black">
                {values.products.map((product, index) => {
                  const associatedSize = product?.data.variants.find(
                    (variant) => {
                      return variant.size === product.size;
                    }
                  );

                  const associatedProduct = associatedSize?.codes.find(
                    (code) => {
                      return code.shortCode === product.code;
                    }
                  );

                  return (
                    <>
                      <CartItemView
                        product={product}
                        associatedProduct={associatedProduct}
                        setRefreshCart={setRefreshCart}
                        index={index}
                      />
                      {index !== values.products?.length - 1 && (
                        <hr className="border-1 w-full border-gray-200"></hr>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">
                  Select Delivery Address
                </h3>
                <button
                  onClick={() => {
                    if (isAuthenticated) {
                      setShowAddAddress((prev) => !prev);
                    } else {
                      toast.error("Please Login to add address");
                    }
                  }}
                  className="text-indigo-500 underline"
                >
                  Add New Address
                </button>
              </div>
              <SelectAddressView />
            </div>
          </div>
          {showAddAddress && (
            <AddAddressView setShowAddAddress={setShowAddAddress} />
          )}
        </div>
      )}
    </>
  );
};
interface CartItemViewProps {
  product: SingleCartItemType;
  associatedProduct: ProductCode | undefined;
  setRefreshCart: Dispatch<SetStateAction<Boolean>>;
  index: number;
}
export const CartItemView = ({
  product,
  associatedProduct,
  setRefreshCart,
  index,
}: CartItemViewProps) => {
  const { updateCartItemsCount } = useCart();

  const navigate = useNavigate();

  return (
    <>
      <div className="hidden md:grid grid-cols-12 gap-2 cursor-pointer justify-start items-center">
        <img
          className="col-span-2 w-full h-auto object-contain"
          src={product.data.image}
          alt="productImage"
        />
        <div className="col-span-8 flex flex-col gap-3">
          <p className="text-lg font-bold">
            {product.data.name}
            {product?.data.genericName &&
              product?.data.category.name === "SURGICAL SUTURES" && (
                <span className="text-md font-medium text-grey">
                  <span className="border border-black mx-2"></span>
                  {product.data.genericName}
                </span>
              )}
          </p>
          <div className="text-slate-400 text-sm flex flex-col gap-2">
            <p>Code : {product.code}</p>
            {product?.data.category.name === "SURGICAL SUTURES" && (
              <p>Size : {product.size}</p>
            )}
          </div>

          <div className="flex flex-row gap-4 items-center">
            <div className="bg-nuvo-grey flex gap-1  border px-4 py-1 rounded-[8px] items-center">
              <label>Qty :</label>
              <Field
                className="h-6 py-2 w-[50px] px-4 rounded-md border border-black"
                type="text"
                id="quantity"
                name={`products[${index}].quantity`}
              />
              <ErrorMessage
                className="text-danger text-sm font-medium"
                name="index.quantity"
                component="div"
              />
            </div>
            <span className="h-5 border border-gray-200"></span>
            <FaTrash
              className="cursor-pointer text-danger text-sm"
              onClick={() => {
                try {
                  let cartItems: SingleCartItemType[] =
                    getLocalStorage("nuvo_cart") || [];

                  if (!Array.isArray(cartItems)) {
                    cartItems = [];
                  }

                  const indexToRemove = cartItems.findIndex(
                    (item) =>
                      item.id === product.id &&
                      item.size === product.size &&
                      item.code === product.code
                  );

                  if (indexToRemove !== -1) {
                    cartItems.splice(indexToRemove, 1);

                    localStorage.setItem(
                      "nuvo_cart",
                      JSON.stringify(cartItems)
                    );
                    toast.success("Cart Deleted Successfully");
                    setRefreshCart((prev) => !prev);
                    updateCartItemsCount();
                  }
                } catch (e) {
                  console.error("Error:", e);
                  toast.error("Something went wrong");
                }
              }}
            />
            <span className="h-5 border border-gray-200"></span>
            <p
              className="cursor-pointer underline text-slate-600 text-sm"
              onClick={() => navigate("/categories")}
            >
              Go To Shop
            </p>
          </div>
        </div>
        <div className="col-span-2 flex flex-col gap-4 justify-between">
          <p className="font-medium text-lg">
            Price :
            {numberToINR(Number(associatedProduct?.discountedPrice.toFixed(2)))}
          </p>
          {associatedProduct?.discountedPrice && (
            <p>
              Total :
              {numberToINR(
                Number(product.quantity * associatedProduct?.discountedPrice)
              )}
            </p>
          )}
        </div>
      </div>
      <div className="md:hidden flex flex-col gap-5">
        <div className="grid grid-cols-3 gap-5 cursor-pointer">
          <img
            className="col-span-1 "
            src={product.data.image}
            alt="productImage"
          />
          <div className="col-span-2">
            <div className="col-span-2 flex flex-col gap-3">
              <p className="text-sm font-bold">
                {product.data.name}
                {product.data?.genericName && (
                  <span className="text-xs font-medium text-grey">
                    <span className="border border-black mx-2"></span>
                    {product.data.genericName}
                  </span>
                )}
              </p>
              <div className="text-xs text-slate-600 flex flex-col gap-2">
                <p>Code : {product.code}</p>
                <p>Size : {product.size}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 items-center">
          <div className="bg-nuvo-grey border px-4 py-1 rounded-[8px] items-center">
            <p>Qty : {product.quantity}</p>
          </div>
          <span className="h-5 border border-black"></span>
          <p
            onClick={() => {
              try {
                let cartItems: SingleCartItemType[] =
                  getLocalStorage("nuvo_cart") || [];

                if (!Array.isArray(cartItems)) {
                  cartItems = [];
                }

                const indexToRemove = cartItems.findIndex(
                  (item) =>
                    item.id === product.id &&
                    item.size === product.size &&
                    item.code === product.code
                );

                if (indexToRemove !== -1) {
                  cartItems.splice(indexToRemove, 1);

                  localStorage.setItem("nuvo_cart", JSON.stringify(cartItems));
                  toast.success("Cart Deleted Successfully");
                  setRefreshCart((prev) => !prev);
                  updateCartItemsCount();
                }
              } catch (e) {
                console.error("Error:", e);
                toast.error("Something went wrong");
              }
            }}
            className="cursor-pointer hover:text-danger hover:underline"
          >
            Delete
          </p>
          <span className="h-5 border border-black"></span>
          <p
            className="cursor-pointer underline text-slate-600 text-lg"
            onClick={() => navigate("/shop")}
          >
            Go To Shop
          </p>
        </div>
        <div className="flex flex-row gap-4 text-sm justify-between">
          <p className="font-bold">
            Price{" "}
            {numberToINR(Number(associatedProduct?.discountedPrice.toFixed(2)))}
          </p>
          {associatedProduct?.discountedPrice && (
            <p>
              Total :
              {numberToINR(
                Number(product.quantity * associatedProduct?.discountedPrice)
              )}
            </p>
          )}
        </div>
      </div>
    </>
  );
};
