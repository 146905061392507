import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FieldGroupRender from "../../../shared/core/fieldGroupRender";
import TextInput from "../../../shared/core/textInput";
import toast, { LoaderIcon } from "react-hot-toast";
import {
  LoginResponse,
  UserProfileFormValues,
} from "../../../../types/user.types";
import {
  editUserHandler,
  getMyDetails,
} from "../../../../handlers/userHandler";
import { getLocalStorage } from "../../../../helpers/authHelper";
import { useNuvoContext } from "../../../../context/NuvoContext";

// Validation schema
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, "Phone number must contain only digits")
    .min(10, "Phone number must be at least 10 digits")
    .required("Phone number is required"),
  GST: Yup.string().nullable(),
});

export const AccountDetails: React.FC = () => {
  const [userData, setUserData] = useState<UserProfileFormValues | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues: UserProfileFormValues = {
    name: userData?.name || "",
    email: userData?.email || "",
    phoneNumber: userData?.phoneNumber || "",
    GST: userData?.GST || null,
  };

  const { handleError } = useNuvoContext();
  const getUserDetails = async () => {
    setIsLoading(true);
    try {
      const user: LoginResponse | null = getLocalStorage("nuvo_user_cred");
      if (user && user.token) {
        const data = await getMyDetails(user.token);

        setUserData({
          name: data.user.name || "",
          email: data.user.email || "",
          phoneNumber: data.user.phoneNumber?.toString() || "",
          GST: data.user.GST || null,
        });
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);
  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          try {
            const user: LoginResponse | null =
              getLocalStorage("nuvo_user_cred");
            if (user && user.token) {
              await editUserHandler(
                values.name,
                values.email,
                Number(values.phoneNumber),
                user?.token,
                values.GST ?? undefined
              );

              toast.success("User Details Updated Successfully");
            }
          } catch (error) {
            console.error("Error submitting form:", error);
            toast.error("Error updating user details");
          }
        }}
        enableReinitialize
      >
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <FieldGroupRender
              buttonElements={
                isEditing
                  ? [
                      <button
                        key="clear"
                        type="button"
                        className="px-6 py-2 bg-red-500 text-white rounded hover:bg-transparent hover:border hover:border-red-500 hover:text-red-500 cursor-pointer"
                        onClick={() => setIsEditing(false)}
                      >
                        Cancel
                      </button>,
                      <button
                        key="submit"
                        type="submit"
                        className="flex items-center bg-blue-500 px-4 py-2 rounded-md w-fit cursor-pointer"
                        disabled={isLoading}
                      >
                        {isLoading && (
                          <LoaderIcon className="w-5 h-5 mr-2 animate-spin" />
                        )}
                        <p>Submit</p>
                      </button>,
                    ]
                  : [
                      <button
                        key="edit"
                        type="button"
                        className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600 cursor-pointer"
                        onClick={toggleEdit}
                      >
                        Edit
                      </button>,
                    ]
              }
              title="Account Details"
            >
              <div className="flex flex-col gap-4">
                <TextInput
                  id="name"
                  name="name"
                  label="Name"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  disabled={!isEditing}
                />
                {touched.name && errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <TextInput
                  id="email"
                  name="email"
                  label="Email"
                  value={values.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  disabled={!isEditing}
                />
                {touched.email && errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <TextInput
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Phone Number"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  placeholder="Enter your phone number"
                  disabled={!isEditing}
                />
                {touched.phoneNumber && errors.phoneNumber && (
                  <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
                )}
              </div>
              <div className="flex flex-col gap-4 mt-4">
                <TextInput
                  id="GST"
                  name="GST"
                  label="GST"
                  value={values.GST || ""}
                  onChange={handleChange}
                  placeholder="Enter your GST Number"
                  disabled={!isEditing}
                />
                {touched.GST && errors.GST && (
                  <p className="text-red-500 text-sm">{errors.GST}</p>
                )}
              </div>
            </FieldGroupRender>
          </Form>
        )}
      </Formik>
    </div>
  );
};
