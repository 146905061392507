// src/components/user/UserManagement.tsx

import { useState } from "react";

import Orders from "../../../pages/Orders";
import Tabs from "../../new-ui/user-management/tabs";
import Addresses from "./tabs/addresses";
import { AccountDetails } from "./tabs/account-details";

export const UserManagement = () => {
  const tabOptions = ["Profile", "Addresses", "Order History"];

  const [activeTab, setActiveTab] = useState<string>("Profile");

  return (
    <div className="p-6 w-full md:w-10/12 lg:w-8/12 mx-auto">
      <div className="flex flex-col p-6">
        <Tabs
          tabs={tabOptions}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
      {activeTab === "Profile" && <AccountDetails />}
      {activeTab === "Addresses" && <Addresses />}
      {activeTab === "Order History" && <Orders />}
    </div>
  );
};
