import React from "react";
import { UserManagement } from "../components/new-ui/user-management/UserManagement";

const UserManagementPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gray-100 ">
      <UserManagement />
    </div>
  );
};

export default UserManagementPage;
