import { useNavigate } from "react-router-dom";
import { EmptyState } from "../../components/shared/empty-states/EmptyState";

const ErrorPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-[60vh] overflow-hidden">
      <EmptyState
        title="Oops! Something went wrong"
        description="It's not you, it's us. Please try again later."
      />
      <div
        className="px-10 py-1 border border-gray-500 bg-primary rounded-lg mt-4 text-md font-normal cursor-pointer text-gray-800"
        onClick={() => navigate("/")}
      >
        <p>Go to homepage</p>
      </div>
    </div>
  );
};

export default ErrorPage;
