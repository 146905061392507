/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getMyOrders } from "../handlers/orderHandler";
import { useNuvoContext } from "../context/NuvoContext";
import { LoginResponse } from "../types/user.types";
import { getLocalStorage } from "../helpers/authHelper";
import { UserOrderData } from "../types/userOrder.types";
import UserOrders from "../components/new-ui/user-management/tabs/MyOrders";
import NuvoShimmer from "../components/new-ui/Homepage/featured-section/nuvo-shimmer";

export default function Orders() {
  const [ordersData, setOrdersData] = useState<UserOrderData | null>(null);
  const { handleError } = useNuvoContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const user: LoginResponse = getLocalStorage("nuvo_user_cred");
      const data = await getMyOrders(user.token);
      setOrdersData(data);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <NuvoShimmer w={"200"} h={10} />;
  }

  return <div>{ordersData && <UserOrders data={ordersData} />}</div>;
}
