// src/components/ContactUs.tsx

import React, { useState } from "react";
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { sendContactMail } from "../config/contact.config";
// import Search from "./Search"; // Uncomment if you integrate Search

interface FormValues {
  name: string;
  phoneNumber: string;
  email: string;
  enquiry: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Phone number is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  enquiry: Yup.string().optional(),
});

export const ContactUs: React.FC = () => {
  const [submissionStatus, setSubmissionStatus] = useState<{
    success: boolean;
    message: string;
  } | null>(null);

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    try {
      await sendContactMail(
        values.name,
        values.phoneNumber,
        values.email,
        values.enquiry
      );
      setSubmissionStatus({
        success: true,
        message: "Your message has been sent successfully!",
      });
      resetForm();
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setSubmissionStatus({
        success: false,
        message:
          "There was an error sending your message. Please try again later.",
      });
    } finally {
      setSubmitting(false);
      // Hide the message after 5 seconds
      setTimeout(() => setSubmissionStatus(null), 5000);
    }
  };

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold text-center mb-12">Contact Us</h1>

      {/* Contact Information and Map */}
      <div className="bg-white border border-gray-200 rounded-lg p-8 mb-12 shadow-sm">
        <h2 className="text-2xl font-semibold mb-6">Get in Touch</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
          {/* Contact Details */}
          <div className="space-y-4">
            <div className="flex items-start">
              <FaMapMarkerAlt className="text-blue-500 w-6 h-6 mr-4 mt-1" />
              <p className="text-gray-700">
                104, Sector-16, HSIIDC, Bahadurgarh, Haryana - 124507, India.
              </p>
            </div>
            <div className="flex items-start">
              <FaPhoneAlt className="text-blue-500 w-6 h-6 mr-4 mt-1" />
              <p className="text-gray-700">+91-7082210770, +91-7082210771</p>
            </div>
            <div className="flex items-start">
              <FaEnvelope className="text-blue-500 w-6 h-6 mr-4 mt-1" />
              <p className="text-gray-700">info@nuvomedsurg.in</p>
            </div>
          </div>

          {/* Embedded Google Map */}
          <div className="w-full h-64 md:h-full rounded-lg overflow-hidden">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.3603413417495!2d76.8873716!3d28.708775299999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0bf3b08a0535%3A0xb50186b57b504836!2sNuvo%20Medsurg%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1734808441202!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="rounded-lg"
            ></iframe>
          </div>
        </div>
      </div>

      {/* Contact Form */}
      <div className="bg-white border border-gray-200 rounded-lg p-8 shadow-sm">
        <h2 className="text-2xl font-semibold mb-6">Send Us a Message</h2>
        {submissionStatus && (
          <div
            className={`mb-4 p-4 rounded-md text-white ${
              submissionStatus.success ? "bg-green-500" : "bg-red-500"
            }`}
          >
            {submissionStatus.message}
          </div>
        )}
        <Formik
          initialValues={{
            name: "",
            phoneNumber: "",
            email: "",
            enquiry: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col gap-4">
              {/* Name Field */}
              <div>
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Your Name<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter your name"
                  className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Phone Number Field */}
              <div>
                <label
                  htmlFor="phoneNumber"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Phone Number<span className="text-red-500">*</span>
                </label>
                <Field
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  placeholder="Enter your phone number"
                  className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Email Field */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Email Address<span className="text-red-500">*</span>
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email address"
                  className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Enquiry Field */}
              <div>
                <label
                  htmlFor="enquiry"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Enquiry
                </label>
                <Field
                  as="textarea"
                  name="enquiry"
                  id="enquiry"
                  placeholder="Enter your enquiry"
                  rows="4"
                  className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-200"
                />
                <ErrorMessage
                  name="enquiry"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-blue-600 text-white py-3 px-6 rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
              >
                {isSubmitting ? "Sending..." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
