// src/data/faqs.ts

import { FAQ } from "./types";

export const faqs: FAQ[] = [
  {
    question: "What are surgical sutures, and why are they important?",
    answer: [
      {
        type: "paragraph",
        content:
          "Surgical sutures are specialized medical devices used to close wounds or surgical incisions by stitching tissues together. They play a critical role in wound healing by providing support, preventing infections, and ensuring proper tissue alignment. Sutures can be absorbable or non-absorbable, depending on whether they dissolve in the body or need to be removed manually. India has emerged as a hub for surgical suture manufacture, offering high-quality products for both domestic and international markets.",
      },
    ],
  },
  {
    question:
      "What types of surgical sutures are commonly manufactured in India?",
    answer: [
      {
        type: "paragraph",
        content: "India produces a wide range of surgical sutures, including:",
      },
      {
        type: "list",
        items: [
          "Absorbable Sutures: Catgut, polyglycolic acid (PGA), and polydioxanone (PDO) sutures, which dissolve naturally in the body.",
          "Non-Absorbable Sutures: Materials like nylon, silk, and polypropylene, which are ideal for long-term wound support and require manual removal.Indian surgical suture manufacturers cater to diverse medical needs, including general surgery, orthopedics, and cardiovascular procedures.",
        ],
      },
    ],
  },
  {
    question: "Who are the leading surgical suture manufacturers in India?",
    answer: [
      {
        type: "paragraph",
        content:
          "Several reputable companies are recognized for their expertise in surgical suture manufacture in India, including:",
      },
      {
        type: "paragraph",
        content:
          "When it comes to surgical sutures in India, Nuvo Medsurg stands out as a trusted name. Renowned for its commitment to precision, quality, and innovation, Nuvo Medsurg offers a comprehensive range of sutures designed to meet the highest international standards, including ISO 13485 and CE certifications.With a focus on delivering cost-effective solutions, Nuvo Medsurg has become a preferred choice for healthcare professionals across hospitals, clinics, and surgical centers in India and beyond. The brand's dedication to excellence ensures that its products contribute to optimal surgical outcomes, earning the trust of surgeons and medical practitioners globally.",
      },
      {
        type: "paragraph",
        content:
          "Choose Nuvo Medsurg for reliable, high-quality surgical sutures that support exceptional patient care.",
      },
    ],
  },
  {
    question:
      " What quality standards do surgical suture manufacturers in India follow?",
    answer: [
      {
        type: "paragraph",
        content:
          "Indian surgical suture manufacturers adhere to rigorous quality control measures and global standards, including:",
      },
      {
        type: "list",
        items: [
          "ISO 13485 Certification: Ensures consistent product quality.",
          "FDA Approvals: For exporting to regulated markets like the USA.",
          "CE Marking: Compliance with European Union safety standards.These certifications highlight India’s leadership in surgical suture manufacture and its ability to meet the highest industry benchmarks.",
        ],
      },
    ],
  },
  {
    question: "Are Indian surgical sutures exported globally?",
    answer: [
      {
        type: "paragraph",
        content:
          "Yes, Indian surgical sutures are widely exported to countries across the USA, Europe, Africa, and Southeast Asia. Manufacturers leverage India’s cost-effective production capabilities while maintaining world-class quality. Exported products include a variety of absorbable and non-absorbable sutures, making India a key player in the global surgical suture market.",
      },
    ],
  },
  {
    question: " How are surgical sutures classified based on usage?",
    answer: [
      {
        type: "paragraph",
        content:
          "Sutures are classified based on their usage and material properties:",
      },
      {
        type: "list",
        items: [
          "Absorbable Sutures: Dissolve within the body, commonly used for internal tissues. Examples include catgut and polyglycolic acid.",
          "Non-Absorbable Sutures: Used for external wounds and require removal, such as silk, nylon, and stainless steel.Indian surgical suture manufacturers offer a broad range of products tailored to different surgical applications, including monofilament and braided options.",
        ],
      },
    ],
  },
  {
    question:
      " What materials are used for manufacturing surgical sutures in India?",
    answer: [
      {
        type: "paragraph",
        content:
          "India’s surgical suture manufacturers utilize a diverse range of materials to cater to specific medical requirements:",
      },
      {
        type: "list",
        items: [
          "Natural Materials: Silk and catgut for absorbable and non-absorbable sutures.",
          "Synthetic Materials: Nylon, polypropylene, polyglycolic acid (PGA), and stainless steel.These materials ensure durability, strength, and minimal tissue reaction, which are essential for successful surgeries.",
        ],
      },
    ],
  },
  {
    question:
      "What advancements are Indian manufacturers making in surgical sutures?",
    answer: [
      {
        type: "paragraph",
        content:
          "Indian manufacturers are driving innovation in the surgical suture industry through:",
      },
      {
        type: "list",
        items: [
          "Antimicrobial Sutures: Coated with agents to prevent infections.",
          "Barbed Sutures: For faster and more efficient wound closure.",
          "Biodegradable Materials: Offering eco-friendly alternatives.These advancements enhance India’s reputation as a leader in surgical suture manufacture and ensure competitive offerings for global markets.",
        ],
      },
    ],
  },
  {
    question:
      "Why are Indian surgical sutures cost-effective compared to other countries?",
    answer: [
      {
        type: "paragraph",
        content:
          "India’s surgical suture manufacturers benefit from low production costs, skilled labor, and advanced manufacturing technologies. These factors allow them to offer high-quality products at competitive prices, making Indian sutures a preferred choice for hospitals and clinics worldwide.",
      },
    ],
  },
  {
    question:
      "How do I choose the right surgical suture manufacturer in India?",
    answer: [
      {
        type: "paragraph",
        content:
          "When selecting a surgical suture manufacturer in India, consider the following:",
      },
      {
        type: "list",
        items: [
          "Certifications: ISO 13485, CE marking, and FDA approvals.",
          "Product Range: Availability of absorbable and non-absorbable sutures.",
          "Reputation: Check customer reviews and industry experience.India’s growing expertise in surgical suture manufacture ensures a reliable supply of quality products for diverse medical needs.",
        ],
      },
    ],
  },
];
