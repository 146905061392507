import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ContentList, Section } from "./static/static";
const data = [
  "Surgical Sutures & Ligatures.",
  "Surgical Disposables: Nitrile Examination Gloves, Latex Examination Gloves, N-95 Mask, 3 Ply Face Mask, Surgeon Cap, Bouffant Cap, Shoe Cover etc.",
  "COVID 19 Range: PPE Kit, Gloves Etc.",
  "Body Cleaning Wipes: Baby Wipes, Bed Bath Towel etc.",
  "Surgical Dressings: Gauze Dressings (Sterile & Non Sterile), Abdominal Sponge, Elastic Adhesive Bandage, Microporous Tape, Haemostatic Gelatine Sponge Etc.",
  "CSSD Consumables: Sterilization reels, Chemical Indicators etc.",
  "Medical Devices: Urine Collection Bag, Urometer",
];

const manufacturingData = [
  "Nuvo Medsurg has developed its infrastructure to manufacture high-quality products. For manufacturing sutures, we have designed ISO Class-7 Clean Rooms for Non-Absorbable Sutures (meeting Class 10000 cleanliness standards approved as per international requirements). We have also designed and developed ISO-6 Clean Rooms for our Absorbable Sutures.",
  "Our infrastructure is developed as per international regulatory agencies. Our plant ensures a non-polluted and non-contaminated environment for quality production. The process ensures fewer toxins before sterilization and delivers the desired quality product post-sterilization.",
];

const qmsData = [
  "The Quality Management System (QMS) is developed to provide assurance that all systems are designed and implemented as per standard norms.",
  "The Quality Management System is reviewed periodically to keep it updated with any changes in practices. This is driven by Quality Assurance and reviewed by the management periodically.",
];

const qmsList = [
  "Strict and clear Quality Policy",
  "Continuous Quality Risk Management",
  "Latest and Robust Technology",
  "Manufacturing Facility Designed as per international standards",
  "Strong Adherence to Standard Operating Procedures",
  "Implementation of Compliance to maintain standard of quality",
  "Regular Internal Audit System for Operating Procedures",
  "Strong follow-up of role & responsibility of Quality Assurance Function",
];

export const AboutUs = () => {
  return (
    <section className="w-full mx-auto py-5">
      {/* Banner Image */}
      <div className="mb-10 md:mb-16">
        <LazyLoadImage
          className="w-11/12 mx-auto h-auto object-cover rounded-lg shadow-md"
          src="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053308/qwo39ou3bgckgzs57ruz.webp"
          alt="About Us Banner"
          loading="lazy"
          effect="blur"
        />
      </div>

      {/* Main Content */}
      <div className="w-11/12 mx-auto flex flex-col gap-12">
        {/* Intro Section */}
        <div className="flex flex-col gap-6">
          <p className="text-sm md:text-base lg:text-lg text-gray-600 text-justify">
            Nuvo Medsurg has its state-of-the-art manufacturing plant at
            Bahadurgarh, Haryana. This plant is equipped with world-class
            manufacturing facilities. Guided by experts and driven by a highly
            skilled team, Nuvo Medsurg leaves no effort in providing the best
            quality products. We manufacture a wide range of products to suit
            the needs of the healthcare sector. Our popular product segments
            include:
          </p>
          <ContentList
            items={data}
            className="text-justify text-slate-500"
            itemClassName="text-sm lg:text-base"
          />
        </div>

        {/* Horizontal Divider */}
        <hr className="border-t border-gray-300 w-full mx-auto" />

        {/* Manufacturing Unit Section */}
        <Section
          title="Manufacturing Unit"
          imageSrc="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053478/grg1ngdgsees8ucypy7q.webp"
          imageAlt="Manufacturing Unit"
          imagePosition="left"
          imageClassName="rounded-lg shadow-md"
        >
          {manufacturingData.map((paragraph, index) => (
            <p
              key={index}
              className="text-justify text-slate-500 text-sm md:text-base"
            >
              {paragraph}
            </p>
          ))}
        </Section>

        {/* Quality Management System Section */}
        <Section
          title="Quality Management System"
          imageSrc="https://res.cloudinary.com/dzqi3eyia/image/upload/v1728053310/h9tmc5n9ootngtuxo9uu.webp"
          imageAlt="Quality Management System"
          imagePosition="right"
          imageClassName="rounded-lg shadow-md"
        >
          <p className="text-justify text-sm md:text-base lg:text-xl text-slate-500">
            {qmsData[0]}
          </p>
          <ContentList
            items={qmsList}
            className="text-justify font-normal text-base md:text-lg "
            itemClassName="text-slate-500 text-sm md:text-base"
          />
          <p className="text-base md:text-lg  text-justify text-slate-500">
            {qmsData[1]}
          </p>
        </Section>
      </div>
    </section>
  );
};
